import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Divider, Switch } from '@material-ui/core'

import { useMiccamChecker, useMicrophone, useStorageSetting } from 'hooks'
import {
    PERMISSIONS_GRANTED,
    PREFFERRED_WEBCAM_SETTING,
    CURSOR_SETTING,
    WEBCAM_SETTING,
} from 'background/constants'

import { getAvailableDevices, getExtensionId } from 'background/services'

import { MicSetting } from 'UI/Components/MicSetting'
import { SpacedGroup } from '../SpacedGroup'
import { VolumeMeter } from './VolumeMeter'
import { DialogAlerts } from './DialogAlerts'
import { DialogCamSettings } from './DialogCamSettings'
import { CaptureBtn } from './CaptureBtn'
import { getFromExtLocalStorage } from '../../../services/extServices'
import { PermissionStatusE } from '../../../types'

const useStyles = makeStyles(theme => ({
    checked: {}, // overrides the check style to nothing, please do not remove
    textOff: {
        color: '#BDBDBD',
    },
    textHighlight: {
        fontSize: '14px',
        fontWeight: 500,
    },
    webcamPreview: {
        width: '100%',
        border: 'none',
        marginBottom: theme.spacing(0.5),
        height: '160px',
        backgroundColor: '#000000',
    },
    divider: {
        margin: theme.spacing(1.5, 0),
        background: 'rgba(9, 12, 16, 0.08)',
    },
}))

type recordingSettingsDialogProps = {
    loadingState: {
        isTrue: boolean
        setTrue: () => void
        setFalse: () => void
    }
    startCapture: () => void
    isRecordingLocked: boolean
}

export const PlaybookSettings = ({
    loadingState,
    startCapture,
    isRecordingLocked,
}: recordingSettingsDialogProps) => {
    // Check permissions for microphone and webcam
    useMiccamChecker()

    const classes = useStyles()
    const { chosenMic, micOptions, onMicChange } = useMicrophone()
    const screenCamSetting = useStorageSetting(WEBCAM_SETTING)

    const { storageSetting: chosenWebcam, changeSetting: setChosenWebcam } =
        useStorageSetting(PREFFERRED_WEBCAM_SETTING)

    const [webcams, setWebcams] = useState<
        Array<{ deviceId: string; label: string }>
    >([])
    const [extId, setExtId] = useState<string>('')
    let { storageSetting: permissionStatus } =
        useStorageSetting(PERMISSIONS_GRANTED)

    const {
        storageSetting: cursorSettings,
        changeSetting: changeCursorSettings,
    } = useStorageSetting(CURSOR_SETTING)

    const getPreselectedWebcam = useCallback(() => {
        getAvailableDevices(devices => {
            getFromExtLocalStorage([PREFFERRED_WEBCAM_SETTING], result => {
                const prefferedWebcamId = result[PREFFERRED_WEBCAM_SETTING]

                const availableWebcams = devices.filter(
                    d => d.kind === 'videoinput'
                )

                const preferredWebcam = availableWebcams.find(
                    device => device.deviceId === prefferedWebcamId
                )

                if (preferredWebcam) {
                    setChosenWebcam(preferredWebcam.deviceId)
                } else {
                    const availableWebcam = availableWebcams.length
                        ? availableWebcams[0].deviceId
                        : ''

                    setChosenWebcam(availableWebcam)
                }

                setWebcams(availableWebcams)
            })
        })
    }, [setChosenWebcam])

    const canStartRecording = () => {
        // Check if we have <all_urls> permission
        if (permissionStatus === PermissionStatusE.QG_BLOCKED) {
            return false
        }

        // Check if mode is selected and mic is either device or muted
        if (permissionStatus === PermissionStatusE.PERMISSIONS_GRANTED) {
            return chosenMic && screenCamSetting.storageSetting
        }

        // Mic can be a device or muted, only allowed mode - no webcam screen recording
        if (
            permissionStatus === PermissionStatusE.WEBCAM_BLOCKED ||
            permissionStatus === PermissionStatusE.MIC_ONLY
        ) {
            return chosenMic && screenCamSetting.storageSetting === 'disabled'
        }

        // Mic can only be muted, only allowed mode - no webcam, no mic screen recording
        if (
            permissionStatus === PermissionStatusE.PERMISSIONS_DENIED ||
            permissionStatus === PermissionStatusE.UNKNOWN_ERROR
        ) {
            return (
                chosenMic === 'mute' &&
                screenCamSetting.storageSetting === 'disabled'
            )
        }

        // If it's some other unexpected case or permissions are loading, disable capture
        return false
    }

    useEffect(() => {
        getExtensionId(id => setExtId(id), {})
    }, [])

    // Preselect settings for a user based on permission status
    useEffect(() => {
        if (!permissionStatus) {
            loadingState.setTrue()
        } else {
            loadingState.setFalse()
        }

        if (permissionStatus === PermissionStatusE.PERMISSIONS_GRANTED) {
            getPreselectedWebcam()
        }

        if (
            permissionStatus === PermissionStatusE.WEBCAM_BLOCKED ||
            permissionStatus === PermissionStatusE.MIC_ONLY
        ) {
            setChosenWebcam('')
            screenCamSetting.changeSetting('disabled')
        }

        if (
            permissionStatus === PermissionStatusE.PERMISSIONS_DENIED ||
            permissionStatus === PermissionStatusE.UNKNOWN_ERROR
        ) {
            onMicChange('mute')
            setChosenWebcam('')
            screenCamSetting.changeSetting('disabled')
        }
        // eslint-disable-next-line
    }, [permissionStatus]) // Run only on permission status change

    return (
        <Box mt={1} width="100%">
            <DialogAlerts permissionStatus={permissionStatus} />

            <DialogCamSettings
                isWebcamModeAvailable={
                    permissionStatus === PermissionStatusE.PERMISSIONS_GRANTED
                }
                type={screenCamSetting.storageSetting}
                onChange={value => {
                    screenCamSetting.changeSetting(value)
                }}
            />

            <Divider classes={{ root: classes.divider }} />

            <SpacedGroup
                justifyContent="space-between"
                alignItems="center"
                my={1}
            >
                <TextField
                    placeholder="Webcam not found"
                    select={Boolean(webcams.length)}
                    disabled={!webcams.length}
                    color="secondary"
                    value={chosenWebcam || ''}
                    onChange={e => setChosenWebcam(e.target.value)}
                    label="Camera"
                    fullWidth
                    SelectProps={{
                        disableUnderline: true,
                        style: {
                            fontSize: '14px',
                            fontWeight: '400',
                            maxWidth: '190px',
                            color: 'rgba(9, 12, 16, 0.6)',
                        },
                    }}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            fontSize: '18px',
                            fontWeight: '500',
                            color: '#090C10',
                        },
                    }}
                    inputProps={{
                        style: {
                            textOverflow: 'ellipsis',
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    {webcams.map((webcam, index) => (
                        <MenuItem key={webcam.deviceId} value={webcam.deviceId}>
                            {webcam.label || `Microphone ${index + 1}`}
                        </MenuItem>
                    ))}
                </TextField>

                {screenCamSetting.storageSetting !== 'maxi' && (
                    <Switch
                        checked={screenCamSetting.storageSetting === 'mini'}
                        size="small"
                        disabled={!webcams.length}
                        onChange={e => {
                            screenCamSetting.changeSetting(
                                e.target.checked ? 'mini' : 'disabled'
                            )
                        }}
                        color="primary"
                    />
                )}
            </SpacedGroup>

            {permissionStatus === PermissionStatusE.PERMISSIONS_GRANTED &&
                chosenWebcam &&
                screenCamSetting.storageSetting !== 'disabled' && (
                    <iframe
                        className={classes.webcamPreview}
                        title="webcam"
                        src={`chrome-extension://${extId}/webcam.html?mode=webcam&deviceId=${chosenWebcam}`}
                        allow={`camera chrome-extension://${extId};microphone chrome-extension://${extId}`}
                    />
                )}

            <Divider classes={{ root: classes.divider }} />

            <MicSetting
                chosenMic={chosenMic}
                micOptions={micOptions}
                onMicChange={onMicChange}
            />

            {chosenMic !== 'mute' && chosenMic && (
                <VolumeMeter chosenMic={chosenMic} />
            )}

            <Divider classes={{ root: classes.divider }} />

            {screenCamSetting.storageSetting !== 'maxi' && (
                <>
                    <SpacedGroup
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                    >
                        <Box
                            className={clsx(classes.textHighlight, {
                                [classes.textOff]: !cursorSettings,
                            })}
                        >
                            Cursor highlight
                        </Box>

                        <Switch
                            checked={Boolean(cursorSettings)}
                            size="small"
                            color="primary"
                            onChange={() => {
                                changeCursorSettings(!cursorSettings)
                            }}
                        />
                    </SpacedGroup>

                    <Divider classes={{ root: classes.divider }} />
                </>
            )}

            <CaptureBtn
                isLocked={isRecordingLocked}
                isDisabled={loadingState.isTrue || !canStartRecording()}
                onClick={() => {
                    startCapture()
                }}
            />
        </Box>
    )
}
