import { useContext } from 'react'
import clsx from 'clsx'
import moment from 'moment'

import {
    Avatar,
    Box,
    IconButton,
    ListItem,
    makeStyles,
    Typography,
    Tooltip,
    Button,
} from '@material-ui/core'
import IconPlay from '@material-ui/icons/PlayArrow'

import { AppContext } from 'app/AppProvider'
import {
    NotificationTypeT,
    UserT,
    NotificationT,
    PlaybookT,
    SpaceT,
} from 'types/notifications'
import { NotificationsContext } from 'UI/Components'

import { openExternalLink, openInApp } from 'background/services'

import gLogo from 'assets/icons/g-label.svg'
import IconPlayInExtension from 'assets/icons/icon-play-in-ext.svg'
import PlaybooksIcon from 'assets/icons/icon-playbooks.svg'

const useStyles = makeStyles(({ spacing }) => ({
    created: {
        fontSize: '10px',
        color: '#78909c',
        textAlign: 'end',
    },
    orgLogo: {
        minHeight: '22px',
        maxHeight: '30px',
        maxWidth: '100%',
        marginBottom: '4px',
    },
    spaceLogo: {
        minHeight: '22px',
        maxHeight: '30px',
        maxWidth: '50px',
        marginRight: spacing(1),
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,

        '& + &': {
            paddingTop: spacing(2),
            marginTop: spacing(2),
            borderTop: '1px solid #eeeeee',
        },
    },
    notificationContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        marginTop: spacing(1),
        padding: spacing(2),
    },
    videoContainer: {
        padding: 0,
        overflow: 'hidden',

        '&:hover': {
            '& $playBtn': {
                opacity: 1,
            },
        },
    },
    button: {
        color: '#212121',
        fontSize: '10px !important',
        padding: spacing(1),
        margin: spacing(0, -1, -1),
        minHeight: 0,
        minWidth: 0,
        width: 'fit-content',

        '&:hover, &:focus': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
    iframe: {
        width: '100%',
        height: '128px',
    },
    smallAvatar: {
        width: spacing(3),
        height: spacing(3),
        fontSize: '12px',
    },
    notificationBodyWrapper: {
        position: 'relative',
        display: 'flex',
        marginTop: spacing(1),
        justifyContent: 'flex-start',
        width: '100%',
    },
    unreadMarkWrapper: {
        top: '16px',
        left: '-12px',
        position: 'absolute',
        width: '8px',
        height: '8px',
        flexShrink: 0,
        borderRadius: '50%',
        backgroundColor: '#cb0000',
    },
    creatorName: {
        fontSize: '10px',
        marginLeft: spacing(1),
    },
    requestContainer: {
        paddingBottom: spacing(1),
        paddingTop: spacing(1),
    },
    videoWrapper: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '56.25%', // when the aspect ratio of a video is 16:9. (9 is 56.25% of 16). If your video is 4:3, set it to 75%.
        background: 'black',
    },
    placeholder: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    placeholderWrapper: {
        height: '145px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    playBtn: {
        width: '50px',
        height: '50px',
        opacity: 0,
        transition: 'opacity 0.3s linear',
        display: 'block',
        background: 'white',
        cursor: 'pointer',
        color: 'black',
        margin: `0 ${spacing(1) / 2}px`,

        '&:hover': {
            opacity: 0.8,
            background: 'white',
        },
    },
    applicationsLogoWrapper: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        backgroundColor: '#fff',
        padding: `${spacing(1)}px ${spacing(2)}px 0 ${spacing(2)}px`,
        alignItems: 'center',
    },
    applicationLogo: {
        maxWidth: '101px',
        maxHeight: '22px',

        '& + &': {
            marginLeft: spacing(1),
        },
    },
    playBtnImg: {
        width: '32px',
        height: '32px',
        position: 'relative',
    },
    playBtnsWrapper: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    },
    viewAllImg: {
        width: spacing(3),
        height: spacing(3),
        marginRight: spacing(1),
    },
}))

type Props = {
    notification: NotificationT
}

const MAX_APPLICATIONS_LOGO_TO_SHOW = 3

export const Notification = ({ notification }: Props) => {
    const classes = useStyles()
    const { clearNotification } = useContext(NotificationsContext)

    const { openVideoPlayer } = useContext(AppContext)

    const acronym = str =>
        str.split(' ').reduce((acronym, word) => acronym + word[0], '')

    const getShortName = (str = '') =>
        str
            .split(' ')
            .reduce(
                (acronym, word, idx) =>
                    idx === 1 ? acronym + word[0] + '.' : acronym + word + ' ',
                ''
            )

    const openVideoPanel = () => {
        if (!notification.playbook) return

        openVideoPlayer(notification.playbook)
        clearNotification(notification.id)
    }

    const handlePlayInExtension = () => {
        if (!notification.playbook) {
            return
        }

        openInApp(null, {
            link: notification.playbook.link,
            playbookId: notification.playbook.id,
            isPlaylist: !!notification.playbook.isPlaylist,
        })

        clearNotification(notification.id)
    }

    const renderNotificationTitle = ({
        type,
        from,
        playbook,
        appName,
        space,
        batchUploadSize,
        percentage,
    }: {
        from?: UserT
        type: NotificationTypeT
        appName: string
        playbook?: PlaybookT
        space?: SpaceT
        batchUploadSize: number | null
        percentage: number | null
    }) => {
        const shortName = getShortName(from?.displayName)

        if (type === 'request') {
            return (
                <>
                    Hey! you got <b>a new Video Request</b>
                    {appName && ` for ${appName}`} from {shortName}
                </>
            )
        }
        if (type === 'ready') {
            return <b>Hey! your New Video Recording is ready</b>
        }
        if (type === 'batchUpload') {
            const singleUploadTitle = <b>Hey! your new video upload is ready</b>
            const batchUploadTitle = (
                <b>{batchUploadSize} new video uploads are ready</b>
            )

            return batchUploadSize === 1 ? singleUploadTitle : batchUploadTitle
        }
        if (type === 'resolveRequest') {
            return (
                <>
                    Hey! {shortName} <b>resolved your Video Request</b>
                    {appName && ` for ${appName}`}
                </>
            )
        }
        if (type === 'share') {
            return (
                <>
                    Hey! {shortName}
                    <b>{` shared a ${
                        playbook?.isPlaylist ? 'playlist' : 'video'
                    }`}</b>{' '}
                    with you {appName && `for ${appName}`}
                </>
            )
        }
        if (type === 'requestShare') {
            return (
                <>
                    Hey! {shortName}
                    <b> shared a video request </b>with you{' '}
                    {appName && `for ${appName}`}
                </>
            )
        }
        if (type === 'view') {
            return (
                <>
                    Hey! {shortName}
                    {from?.organization?.name &&
                        ` from ${from?.organization.name}`}
                    <b>{` viewed${
                        percentage && percentage > 0 ? ` ${percentage}% of` : ''
                    } your ${playbook?.isPlaylist ? 'playlist' : 'video'}`}</b>
                </>
            )
        }
        if (type === 'addToSpace') {
            return (
                <>
                    Hey! {shortName} from {from?.organization?.name}
                    <b> added you to Space </b>
                    {space?.name}
                </>
            )
        }
    }

    const renderApplicationsLogos = applications => {
        const filteredApplications = (applications || []).filter(
            ({ logo }) => logo
        )

        if (!filteredApplications.length) {
            return null
        }

        return (
            <Box className={classes.applicationsLogoWrapper}>
                {filteredApplications
                    .slice(0, MAX_APPLICATIONS_LOGO_TO_SHOW)
                    .map(({ title, logo }) => (
                        <img
                            key={title}
                            src={logo}
                            alt={title}
                            className={classes.applicationLogo}
                        />
                    ))}
            </Box>
        )
    }

    const renderNotificationDate = date => {
        const dateDifference = Date.now() - date
        const minuteInMs = 1000 * 60
        const yesterday = moment(Date.now())
            .clone()
            .subtract(1, 'days')
            .startOf('day')
        const today = moment().startOf('day')
        const wasCreatedYesterday = moment(date).isSame(yesterday, 'd')
        const wasCreatedToday = moment(date).isSame(today, 'd')
        let textTime

        if (dateDifference < minuteInMs) {
            textTime = 'Just now'
        } else if (wasCreatedToday) {
            textTime = moment(date).fromNow(true)
        } else if (wasCreatedYesterday) {
            textTime = 'Yesterday'
        } else {
            textTime = moment(date).format('MMM DD YYYY, HH:mm')
        }

        return textTime
    }

    return (
        <ListItem className={classes.listItem}>
            <Box display="flex" flexDirection="column" width="100%">
                {' '}
                <Typography className={classes.created}>
                    {renderNotificationDate(notification.dateCreated)}
                </Typography>
                <Box className={classes.notificationBodyWrapper}>
                    {notification.status === 'new' && (
                        <Box className={classes.unreadMarkWrapper} />
                    )}
                    {notification.from ? (
                        <Avatar src={notification.from?.photoURL}>
                            {notification.from.organization &&
                                acronym(
                                    notification.playbook?.user.displayName ||
                                        ''
                                )}
                        </Avatar>
                    ) : (
                        <Avatar src={gLogo} />
                    )}
                    <Box ml={1} flexGrow={1}>
                        <Box
                            minHeight="40px"
                            display="flex"
                            alignItems="center"
                        >
                            <Typography variant="body2">
                                {renderNotificationTitle({
                                    type: notification.type,
                                    from: notification.from!,
                                    playbook: notification.playbook,
                                    appName: notification.appName || '',
                                    space: notification.space,
                                    batchUploadSize:
                                        notification.batchUploadSize,
                                    percentage: notification.percentage,
                                })}
                            </Typography>
                        </Box>

                        {notification.pbRequest && (
                            <Box
                                className={clsx(
                                    classes.notificationContainer,
                                    classes.requestContainer
                                )}
                            >
                                {notification.pbRequest.application?.logo && (
                                    <img
                                        className={classes.orgLogo}
                                        src={
                                            notification.pbRequest.application
                                                .logo
                                        }
                                        alt={
                                            notification.pbRequest.application
                                                .title
                                        }
                                    />
                                )}

                                <Typography variant="body2">
                                    <b>{notification.pbRequest?.title}</b>
                                </Typography>
                                {notification.pbRequest && (
                                    <Button
                                        variant="text"
                                        className={classes.button}
                                        onClick={() => {
                                            openExternalLink(null, {
                                                url: `playbookRequests/${notification.pbRequest?.id}`,
                                            })
                                            clearNotification(notification.id)
                                        }}
                                    >
                                        View request
                                    </Button>
                                )}
                            </Box>
                        )}

                        {notification.playbook && (
                            <>
                                <Box
                                    className={clsx(
                                        classes.notificationContainer,
                                        classes.videoContainer
                                    )}
                                >
                                    <Box className={classes.placeholderWrapper}>
                                        <img
                                            alt={notification.playbook?.title}
                                            className={classes.placeholder}
                                            src={
                                                notification.playbook
                                                    .screenshotUrl
                                            }
                                        />
                                        <Box
                                            className={classes.playBtnsWrapper}
                                        >
                                            <IconButton
                                                size="small"
                                                className={classes.playBtn}
                                                onClick={openVideoPanel}
                                            >
                                                <IconPlay fontSize="large" />
                                            </IconButton>
                                            {notification.playbook.link && (
                                                <Tooltip title="Play on app">
                                                    <IconButton
                                                        className={
                                                            classes.playBtn
                                                        }
                                                        onClick={
                                                            handlePlayInExtension
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                classes.playBtnImg
                                                            }
                                                            src={
                                                                IconPlayInExtension
                                                            }
                                                            alt="play in extension"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                        {renderApplicationsLogos(
                                            notification.playbook.applications
                                        )}
                                    </Box>
                                    <Box p={2}>
                                        <Typography variant="body2">
                                            <b>
                                                {notification.playbook?.title}
                                            </b>
                                        </Typography>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            mt={1}
                                        >
                                            <Avatar
                                                className={classes.smallAvatar}
                                                src={
                                                    notification.playbook.user
                                                        .photoURL
                                                }
                                            >
                                                {acronym(
                                                    notification.playbook?.user
                                                        .displayName || ''
                                                )}
                                            </Avatar>
                                            <Typography
                                                className={classes.creatorName}
                                            >
                                                {getShortName(
                                                    notification.playbook?.user
                                                        .displayName || ''
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {Number(notification.batchUploadSize) > 1 && (
                                    <Box mt={1}>
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            onClick={() => {
                                                openExternalLink(null, {
                                                    url: 'playbooks/your-playbooks',
                                                })
                                                clearNotification(
                                                    notification.id
                                                )
                                            }}
                                        >
                                            <img
                                                className={classes.viewAllImg}
                                                src={PlaybooksIcon}
                                                alt="All your video playbooks"
                                            />
                                            View All
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )}

                        {notification.space && (
                            <Box>
                                <Button
                                    variant="text"
                                    className={classes.button}
                                    onClick={() => {
                                        openExternalLink(null, {
                                            url: `spaces/library/${notification.space?.id}`,
                                        })
                                        clearNotification(notification.id)
                                    }}
                                >
                                    {notification.space.logo && (
                                        <img
                                            className={classes.spaceLogo}
                                            src={notification.space.logo}
                                            alt={notification.space.name}
                                        />
                                    )}
                                    Go to space {notification.space.name}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </ListItem>
    )
}
