import React, { memo, useMemo, useEffect, useRef } from 'react'
import { Segment } from '@guidde-co/shared.transcript.segment'

import { Box, Typography } from '@material-ui/core'

import { logToAnalytics } from 'background/services'

import { useRealtimeVideoTime } from 'hooks'
import { generatePlayerId } from 'services/video'
import {
    SegmentType,
    Subtitle,
    TranscriptRange,
} from '../../../types/transcript'
import { SearchTranscript } from './SearchTranscript'
import { PlaybookType } from 'types'
import { makeStyles } from '@material-ui/core/styles'

/*
    extract all the subtitles from all the segments into
    a single array that will be used later in edit mode
*/
const extractSubtitles = (transcript: Array<SegmentType> = []) => {
    const res: Array<Subtitle> = []
    transcript?.forEach((segment: SegmentType) => {
        segment?.subtitles?.forEach((subtitle: Subtitle) => {
            res.push({
                text: subtitle.text,
                start: subtitle.start,
                duration: subtitle.duration,
            })
        })
    })
    return res
}

/*
    Generate Transcript Ranges which is an array of objects, for example:
    [{
        start :0,
        finish: 4.8
    }]
    This is used to calculate the active subtitle and add to it a css red background
    in DictionaryWrapper transcriptText css class
*/

const generateTranscriptRanges = (transcript: Array<SegmentType> = []) => {
    if (!transcript) return []

    const res: Array<TranscriptRange> = []

    transcript.forEach((segment: SegmentType) => {
        segment?.subtitles?.forEach((subtitle: Subtitle) => {
            res.push({
                start: subtitle.start,
                finish: subtitle.start + subtitle.duration,
            })
        })
    })
    return res
}

const scrollToElement = el => {
    if (!el) return
    el.scrollIntoView({ behavior: 'smooth' })
}

const checkActiveSegment = (realVideoTime, transcriptRanges, idx) => {
    if (!transcriptRanges[idx]) return false
    return (
        transcriptRanges[idx].start < realVideoTime &&
        transcriptRanges[idx].finish > realVideoTime
    )
}

type Props = {
    transcriptUrl: string
    playbook: PlaybookType
    startTime?: number
    transcript?: any
}

const useStyles = makeStyles(() => ({
    dialogContent: {
        overflowY: 'scroll',
        maxHeight: 'calc(100vh - 150px)',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '20px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D8D8D8',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
        },
    },
}))

export const Transcript = memo(
    ({ startTime = 0, transcript = [], playbook }: Props) => {
        const realVideoTime = useRealtimeVideoTime() || startTime
        const classes = useStyles()
        const activeSegmentRef = useRef<number>(0)

        const mapList = useMemo(() => new Map(), [])

        const subtitles = extractSubtitles(transcript)

        const transcriptRanges = useMemo(
            () => generateTranscriptRanges(transcript),
            [transcript]
        )

        useEffect(() => {
            if (!mapList.size) return

            const ind = subtitles?.findIndex((_, idx) =>
                checkActiveSegment(realVideoTime, transcriptRanges, idx)
            )

            if (ind === -1) return

            const activeIndex = ind === undefined || ind < 0 ? 0 : ind

            const activeSegment = Array.from(mapList)[activeIndex]?.[1]

            if (activeSegmentRef.current !== activeIndex && activeSegment) {
                activeSegmentRef.current = activeIndex
                scrollToElement(activeSegment)
            }
        }, [mapList, realVideoTime, transcriptRanges, transcript, subtitles])

        // Search result item was clicked, scroll the transcript to
        // the clicked search result
        const handleSearchItemClick = (item: Subtitle) => {
            playVideo(item)
        }

        const playVideo = (item: any) => {
            const playerId = generatePlayerId()
            const player = document.getElementById(playerId) as HTMLVideoElement
            player.currentTime = item.start

            logToAnalytics('timestampClicked', {
                timeOffset: item.time,
            })
        }

        if (subtitles?.length === 0) {
            return (
                <Typography>
                    There is no transcript for this video. Please check again
                    later.
                </Typography>
            )
        }

        return (
            <>
                <SearchTranscript
                    playbook={playbook}
                    subtitles={subtitles}
                    videoRef={null}
                    onItemClick={handleSearchItemClick}
                />
                <Box className={classes.dialogContent}>
                    {subtitles?.map((subtitle, idx) => {
                        return (
                            <div
                                ref={node => {
                                    mapList.set(
                                        subtitles[idx]?.start || 0,
                                        node
                                    )
                                }}
                                key={idx}
                            >
                                <Segment
                                    subtitle={subtitle}
                                    onClick={() => playVideo(subtitle)}
                                    isActive={checkActiveSegment(
                                        realVideoTime,
                                        transcriptRanges,
                                        idx
                                    )}
                                />
                            </div>
                        )
                    })}
                </Box>
            </>
        )
    }
)
