import { makeStyles } from '@material-ui/core/styles'

import { generatePlayerId } from 'services/video'

const useStyles = makeStyles({
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
})

type Props = {
    playbookURL: string
    defaultSpeed?: number
    startTime?: number
}

export const VideoPlayer = ({
    playbookURL,
    defaultSpeed,
    startTime,
}: Props) => {
    const classes = useStyles()

    return (
        <video
            controls
            autoPlay
            controlsList="nodownload noplaybackrate"
            className={classes.video}
            src={playbookURL}
            id={generatePlayerId()}
            onLoadedMetadata={e => {
                const player = e.target as HTMLVideoElement
                if (!player) return

                if (defaultSpeed && defaultSpeed > 1) {
                    player.playbackRate = defaultSpeed
                }

                if (startTime) {
                    player.currentTime = startTime
                }
            }}
        />
    )
}
