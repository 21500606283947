import Box from '@material-ui/core/Box'

import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import { useParentLocation } from 'hooks'
import { PermissionStatusE } from 'types'

const useStyles = makeStyles(() => ({
    alertBody: {
        backgroundColor: '#FEFAE4',
        fontWeight: 400,
        fontSize: '12px',
        color: 'rgba(9, 12, 16, 0.6)',
    },
    alertTitle: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#090C10',
    },
}))

export const DialogAlerts = ({
    permissionStatus,
}: {
    permissionStatus?: `${PermissionStatusE}`
}) => {
    if (!permissionStatus) return null

    const classes = useStyles()
    const location = useParentLocation()
    const blockedBy =
        location?.origin !== undefined
            ? `or blocked by ${location?.origin}`
            : ''
    const errorText = `The webcam is used by another application ${blockedBy}`

    return (
        <>
            {permissionStatus === PermissionStatusE.PERMISSIONS_DENIED && (
                <Box my={2}>
                    <Alert severity="warning" className={classes.alertBody}>
                        <AlertTitle className={classes.alertTitle}>
                            Microphone and webcam access is blocked
                        </AlertTitle>
                        Only muted screen recordings are available in this mode.
                        <br />
                        Allow access in your browser or system settings to
                        experience the full power of Guidde.
                    </Alert>
                </Box>
            )}

            {permissionStatus === PermissionStatusE.UNKNOWN_ERROR && (
                <Box my={2}>
                    <Alert severity="warning" className={classes.alertBody}>
                        <AlertTitle className={classes.alertTitle}>
                            Microphone and webcam are not detected
                        </AlertTitle>
                        Only muted screen recordings are available in this mode.
                    </Alert>
                </Box>
            )}

            {permissionStatus === PermissionStatusE.WEBCAM_BLOCKED && (
                <Box my={2}>
                    <Alert
                        severity="warning"
                        color="info"
                        className={classes.alertBody}
                    >
                        <AlertTitle className={classes.alertTitle}>
                            Cannot start webcam
                        </AlertTitle>
                        {errorText}
                        <Box>You can still record in "Screen only" mode.</Box>
                    </Alert>
                </Box>
            )}

            {permissionStatus === PermissionStatusE.MIC_ONLY && (
                <Box my={2}>
                    <Alert
                        severity="warning"
                        color="info"
                        className={classes.alertBody}
                    >
                        <AlertTitle className={classes.alertTitle}>
                            Could not detect a webcam device.
                        </AlertTitle>
                        <Box>You can still record in "Screen only" mode.</Box>
                    </Alert>
                </Box>
            )}

            {permissionStatus === PermissionStatusE.QG_BLOCKED && (
                <Box my={2}>
                    <Alert
                        severity="warning"
                        color="info"
                        className={classes.alertBody}
                    >
                        <AlertTitle className={classes.alertTitle}>
                            Allow screen access
                        </AlertTitle>
                        <Box>
                            Allow this extension to access all sites in this
                            link and start capturing.
                        </Box>
                    </Alert>
                </Box>
            )}
        </>
    )
}
