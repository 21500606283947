import { captureSentryEvent } from '../../services/sentry'
import { SDK_ERRORS } from '../parentConfig'

export const RESERVED_USER_VARS = new Set([
    'debug',
    'userId',
    'userEmail',
    'userName',
    'accountId',
    'accountName',
])

const RESERVED_CONFIGURATION_VARS = new Set(['embed'])

type ValidateUserPropsType = {
    userInfo: any
    configProps: any
}

const defaultReturn: ValidateUserPropsType = { userInfo: null, configProps: {} }

export const validateUserProps = rawUserInfo => {
    if (!rawUserInfo) {
        return defaultReturn
    }

    const isObj = typeof rawUserInfo === 'object' && !Array.isArray(rawUserInfo)
    if (!isObj) {
        captureSentryEvent(SDK_ERRORS.WRONG_USER_TYPE, true, { rawUserInfo })
        return defaultReturn
    }

    const configProps = Object.entries(rawUserInfo).reduce(
        (acc, [key, value]) => {
            if (RESERVED_CONFIGURATION_VARS.has(key)) {
                acc[key] = value
            }
            return acc
        },
        {}
    )

    const dataSize = JSON.stringify(rawUserInfo).length
    if (dataSize > 512) {
        captureSentryEvent(SDK_ERRORS.USER_TOO_LARGE, true, { rawUserInfo })
        return defaultReturn
    }

    const userInfo = Object.entries(rawUserInfo)
        .filter(([key]) => !RESERVED_CONFIGURATION_VARS.has(key))
        .reduce(
            (acc, [key, value]) => ({
                ...acc,
                // Stringify standard properties, other properties can be of any type
                [key]: RESERVED_USER_VARS.has(key) ? String(value) : value,
            }),
            {}
        )

    return { userInfo, configProps }
}
