import debounce from 'lodash/debounce'

import {
    disablePointersOnIframes,
    restorePointersOnIframes,
} from '../../services/styleFixer'
import {
    getFromExtLocalStorage,
    setToExtLocalStorage,
} from '../../services/extServices'

const setDnDCoordsDebounced = debounce(
    (storageSetting, storageValue) =>
        setToExtLocalStorage(storageSetting, storageValue),
    1000
)

export const enableDragControl = (
    dragElement: HTMLElement,
    dragHandle?: HTMLElement,
    storageSetting?: string
) => {
    const handle = dragHandle || dragElement

    if (!handle || !dragElement) return

    const moveTo = (left: string, top: string) => {
        dragElement.style.left = left
        dragElement.style.top = top

        if (storageSetting) {
            setDnDCoordsDebounced(storageSetting, { left, top })
        }
    }

    if (storageSetting) {
        getFromExtLocalStorage([storageSetting], result => {
            const dndPosition = result[storageSetting]

            if (dndPosition) {
                moveTo(dndPosition.left, dndPosition.top)
            }
        })

        chrome?.storage?.onChanged.addListener((changedStorage, storage) => {
            if (storage === 'local') {
                if (changedStorage[storageSetting]) {
                    const { newValue } = changedStorage[storageSetting]
                    if (newValue) {
                        moveTo(newValue.left, newValue.top)
                    }
                }
            }
        })
    }

    handle.addEventListener('mousedown', e => {
        /* If dragging panel over iframe, events won't bubble and dragging won't work.
            So while dragging, we temporary disable pointer-events for all the iframes on a page. */
        disablePointersOnIframes()

        const bounds = dragElement.getBoundingClientRect()
        let shiftX = e.pageX - bounds.left
        let shiftY = e.pageY - bounds.top

        const moveWithShift = (pageX: number, pageY: number) =>
            moveTo(pageX - shiftX + 'px', pageY - shiftY + 'px')

        moveWithShift(e.pageX, e.pageY)

        const onMove = e => {
            moveWithShift(e.pageX, e.pageY)
        }

        window.addEventListener('mousemove', onMove)
        window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', onMove)

            restorePointersOnIframes()
        })
    })
}
