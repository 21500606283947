import { ServicesUsageType } from '@guidde/common-types/fsdb'
import { uuid } from '../services/utils'

import { DEV_EXT_ID } from './constants'
import { isLocal } from '../services/environmentService'
import {
    GetOrganizationCbType,
    PlaybookType,
    LanguageType,
    TopicType,
    QuickActionConfig,
    QuickActionResult,
    UserCallback,
    RecordingStatus,
    PlaybookBeforeUpload,
    RecPanelMode,
} from '../types'
import {
    BackgroundAPIMsg,
    ExtensionFunctionalityE,
    FirebaseRequestE,
    GuiddeRequestE,
    PlaybookRecordingE,
    QuickGuiddeFunctionalityE,
} from '../types/backgroundAPI'
import { PermanentListenerE } from '../types/contentScriptAPI'
import { AnalyticsEvent } from '../types/analytics'
import { handleLastError } from '../services/extServices'
import { QG_STATE_UNION } from '../types/quickGuidde'

const callbacks: Record<string, any> = {}

const getMessageKey = (action: string, id: string | null) =>
    id ? `${action}-${id}` : action

chrome.runtime?.onMessage?.addListener(msg => {
    const msgKey = getMessageKey(msg.action, msg.id)

    if (callbacks[msgKey]) {
        // Delete executed callbacks only for non-permanent action
        if (msg.id) {
            callbacks[msgKey](msg.response)
            delete callbacks[msgKey]
        } else {
            /* This is a hotfix that requires further investigation.
             * Sometimes we get empty response (this happens only for PERMANENT actions).
             * If that's the case - do not invoke a callback.*/
            if (msg.response !== undefined) {
                callbacks[msgKey](msg.response)
            }
        }
    }

    return false
})

const noop = () => {}
const sendMessageToBackground = async (
    action: BackgroundAPIMsg,
    callback,
    params: any = undefined,
    isPermanent: boolean = false // Permanent action won't have an ID and its callback won't be deleted after execution
) => {
    if (SDK_MODE) {
        const sdkScript = SDK_MODE ? require('./background.sdk') : null // Loads to bundle without this check
        const response = await sdkScript?.default?.({ action, params })
        callback?.(response)
    } else {
        // Message id is required to distinguish callbacks for the same action type in production
        const messageID = isPermanent ? null : uuid()
        const message = { action, params, id: messageID }

        if (isLocal) {
            // When it's a development build (localhost:3000), send message to background via External Messaging
            // and execute callback
            chrome.runtime?.sendMessage(DEV_EXT_ID, message, callback || noop)
        } else {
            // When it's an extension build, send message to background and listen to messages in return
            if (callback) {
                const messageKey = getMessageKey(action, messageID)
                callbacks[messageKey] = callback
            }

            chrome.runtime.sendMessage(message, () => {
                if (chrome.runtime.lastError) {
                    handleLastError(chrome.runtime.lastError.message)
                }
            })
        }
    }
}

// TODO: Refactor permanent callbacks to permanent listeners to avoid callback conflicts
// Permanent callbacks (not deleted after first response is received)
export const getFirebaseUser = (callback: UserCallback) =>
    sendMessageToBackground('getFirebaseUser', callback, null, true)

export const getNotifications = (callback, params) =>
    sendMessageToBackground('getNotifications', callback, params, true)

export const subscribeToRecordingStatus = (
    callback: ({ status }: { status: RecordingStatus }) => void
) =>
    sendMessageToBackground(
        PlaybookRecordingE.SUBSCRIBE_TO_REC_STATUS,
        callback,
        {},
        true
    )

// Regular callbacks (invoked once)
export const getOrganizationData = (
    callback: (orgData: GetOrganizationCbType) => void
) => sendMessageToBackground('getOrganizationData', callback, {})

export const getPlaybooks = (callback, params) =>
    sendMessageToBackground('getPlaybooks', callback, params)

export const getApps = callback => sendMessageToBackground('getApps', callback)

export const getUserSpaces = (callback, params) =>
    sendMessageToBackground('getUserSpaces', callback, params)

export const getUserAchievements = callback =>
    sendMessageToBackground('getUserAchievements', callback)

export const markAllNotificationsAsRead = (callback, params) =>
    sendMessageToBackground('markAllNotificationsAsRead', callback, params)

export const removeNotification = (callback, params) =>
    sendMessageToBackground('removeNotification', callback, params)

export const getOrgUsers = callback =>
    sendMessageToBackground('getOrgUsers', callback, {})

export const requestPlaybook = (callback, params) =>
    sendMessageToBackground('requestPlaybook', callback, params)

export const getPlaybookSteps = (callback, params) =>
    sendMessageToBackground('getPlaybookSteps', callback, params)

export const sharePlaybookOnly = (callback, params) =>
    sendMessageToBackground('sharePlaybookOnly', callback, params)

export const getPlaybookOptions = (callback, params) =>
    sendMessageToBackground('getPlaybookOptions', callback, params)

export const getPlaybook = (
    callback: (r: PlaybookType | null) => void,
    params: { playbookId: string }
) => sendMessageToBackground(GuiddeRequestE.GET_PLAYBOOK, callback, params)

export const getTranscript = (callback, params: { transcriptUrl: string }) =>
    sendMessageToBackground('getTranscript', callback, params)

export const getServiceUsage = (callback: (usage: ServicesUsageType) => void) =>
    // subscribe to internal LISTEN_SERVICE_USAGE_UPDATE event and get current value of serviceUsage
    sendMessageToBackground(FirebaseRequestE.GET_SERVICE_USAGE, callback)

export const triggerVideoPlayer = (
    callback,
    params: { playbook: any; withDelay: boolean }
) => sendMessageToBackground('triggerVideoPlayer', callback, params)

export const getPlaybookList = (callback, params: { playbookId: string }) =>
    sendMessageToBackground(GuiddeRequestE.GET_PLAYBOOK_LIST, callback, params)

export const getPbRequestDefaults = callback =>
    sendMessageToBackground('getPbRequestDefaults', callback)

export const logToAnalytics = (event: AnalyticsEvent, params = {}) => {
    const tabInfo = {
        'current-app': window._GUIDDE?.CURRENT_APP,
        'current-domain': window._GUIDDE?.CURRENT_DOMAIN,
        'default-tags': window._GUIDDE?.DEFAULT_TAGS,
    }
    sendMessageToBackground('logToAnalytics', null, {
        event,
        params: { ...tabInfo, ...params },
    })
}

export const sendInvite = (callback, params) =>
    sendMessageToBackground('sendInvite', callback, params)

export const getTagConditions = (callback, params: { domain: string }) =>
    sendMessageToBackground('getTagConditions', callback, params)

export const openExternalLink = (callback, params) =>
    sendMessageToBackground('openExternalLink', callback, params)

export const getPlaybookRecordingStatus = (
    callback: ({ status }: { status: RecordingStatus }) => void
) => sendMessageToBackground(PlaybookRecordingE.GET_REC_STATUS, callback, {})

export const startScreenCapture = (
    callback,
    params: { defaultFilters: any; link: string }
) =>
    sendMessageToBackground(PlaybookRecordingE.START_PLAYBOOK, callback, params)

export const cancelScreenCapture = () =>
    sendMessageToBackground(PlaybookRecordingE.CANCEL_PLAYBOOK, null, {})

export const restartScreenCapture = () =>
    sendMessageToBackground(PlaybookRecordingE.RESTART_PLAYBOOK, null, {})

export const stopScreenCapture = (params: { mxpMeta: any }) =>
    sendMessageToBackground(PlaybookRecordingE.STOP_PLAYBOOK, null, params)

export const getRecordingStartTime = callback =>
    sendMessageToBackground(PlaybookRecordingE.GET_REC_START_TIME, callback)

export const startCountdownAndWebcam = () =>
    sendMessageToBackground(
        PlaybookRecordingE.START_COUNTDOWN_AND_WEBCAM,
        null,
        {}
    )

export const startEventsWatcher = (params: { startRecTime: number }) =>
    sendMessageToBackground(PlaybookRecordingE.START_REC_OBSERVER, null, params)
export const stopEventsWatcher = (params: {
    isCancelled: boolean
    endRecTime: number
}) =>
    sendMessageToBackground(PlaybookRecordingE.STOP_REC_OBSERVER, null, params)

export const addChapter = () =>
    sendMessageToBackground(PlaybookRecordingE.ADD_NEW_CHAPTER, null, {})

export const updateRecordingStatus = (params: { newStatus: RecordingStatus }) =>
    sendMessageToBackground(PlaybookRecordingE.UPDATE_REC_STATUS, null, params)

export const triggerPlaybookUpload = (params: { storagePath: string }) =>
    sendMessageToBackground(GuiddeRequestE.TRIGGER_FILE_UPLOAD, null, params)

export const playbookMetaUpload = (params: {
    uploadMetaData: PlaybookBeforeUpload
}) => sendMessageToBackground(GuiddeRequestE.PLAYBOOK_META_UPLOAD, null, params)

export const getExtensionId = (callback, params) =>
    sendMessageToBackground('getExtensionId', callback, params)

export const getAvailableDevices = callback =>
    sendMessageToBackground('getAvailableDevices', callback)

export const sendViewNotification = (callback, params) =>
    sendMessageToBackground('sendViewNotification', callback, params)

export const getPlayMessage = (callback, params) =>
    sendMessageToBackground('getPlayMessage', callback, params)

export const openInApp = (callback, params) =>
    sendMessageToBackground('openInApp', callback, params)

export const updateContextMenu = (params: {
    contextMenus: string[]
    updateProps: any
}) => sendMessageToBackground('updateContextMenu', null, params)

export const getKeyboardShortcuts = (
    callback: (
        commands: { name?: string; description?: string; shortcut?: string }[]
    ) => void
) => sendMessageToBackground('getKeyboardShortcuts', callback)

export const searchFromSdkApi = (callback, params) =>
    sendMessageToBackground('searchFromSdkApi', callback, params)

export const openLibrary = (
    callback,
    params: { preOpen: boolean; libraryMode?: 'quickAction' }
) =>
    sendMessageToBackground(
        ExtensionFunctionalityE.OPEN_LIBRARY,
        callback,
        params
    )

export const getAllSitePermissions = (
    callback: (isQGAllowed: boolean) => void
) =>
    sendMessageToBackground(
        ExtensionFunctionalityE.GET_QG_PERMISSIONS,
        callback
    )

export const changeRecPanelMode = (
    params: { mode: RecPanelMode },
    callback?: (r: 'SUCCESS' | 'FAIL') => void
) =>
    sendMessageToBackground(
        ExtensionFunctionalityE.CHANGE_REC_PANEL_MODE,
        callback || null,
        params
    )

export const openRecording = () =>
    sendMessageToBackground(ExtensionFunctionalityE.OPEN_REC_IFRAME, null, {})

export const getQuickActionConfig = (
    callback: (config: QuickActionConfig[]) => void
) => sendMessageToBackground(GuiddeRequestE.GET_QUICK_ACTION_CONFIG, callback)

// QG
export const establishNewConnection = () =>
    sendMessageToBackground('establishNewConnection', null, {})

export const getQGRecordingState = (
    callback: (response: { state: QG_STATE_UNION }) => void | undefined
) =>
    sendMessageToBackground(
        QuickGuiddeFunctionalityE.GET_QG_STATE,
        callback,
        {}
    )

export const startQGRecording = params =>
    sendMessageToBackground('startQGRecording', null, params)

export const stopQGRecording = (params: { currentHost: string }) =>
    sendMessageToBackground('stopQGRecording', null, params)

export const stopQGRecordingNow = params =>
    sendMessageToBackground('stopQGRecordingNow', () => {}, params)

export const stopQGRecordingAfterChangeStep = () =>
    sendMessageToBackground('stopQGRecordingAfterChangeStep', () => {}, {})

export const sendManualQGStep = (noFrame?: boolean) =>
    sendMessageToBackground('sendManualQGStep', null, { noFrame })

export const getLastStepUrlByIndex = callback => {
    sendMessageToBackground('getLastStepUrlByIndex', callback)
}

export const handleResizeEvent = params =>
    sendMessageToBackground('handleResizeEvent', () => {}, params)

export const getResizeMetaData = callback =>
    sendMessageToBackground('getResizeMetaData', callback)

export const getStepTextLimit = callback =>
    sendMessageToBackground('getStepTextLimit', callback)

export const deleteQGRecording = () =>
    sendMessageToBackground('deleteQGRecording', null)

export const restartQGRecording = () =>
    sendMessageToBackground('restartQGRecording', null)

export const pauseQGRecording = () =>
    sendMessageToBackground(QuickGuiddeFunctionalityE.PAUSE_QG, null)

export const resumeQGRecording = () =>
    sendMessageToBackground(QuickGuiddeFunctionalityE.RESUME_QG, null)

export const pauseResumeQGRecording = () =>
    sendMessageToBackground(QuickGuiddeFunctionalityE.PAUSE_RESUME_QG, null)

export const isRecInProgress = (callback: (response: boolean) => void) =>
    sendMessageToBackground(
        ExtensionFunctionalityE.IS_REC_IN_PROGRESS,
        callback
    )

export const insertQuickActionResult = (params: QuickActionResult) =>
    sendMessageToBackground(
        ExtensionFunctionalityE.INSERT_QA_RESULT,
        null,
        params
    )

export const getMagicCaptureConfig = (
    callback: (response: {
        captureTypes: TopicType[]
        languages: LanguageType[]
    }) => void
) => sendMessageToBackground(GuiddeRequestE.GET_MAGIC_CAPTURE_CONFIG, callback)

/* PERMANENT LISTENERS
 *
 * Use permanent listeners whenever you need to execute something in React on background script command.
 * Permanent listener's action name should be unique.
 * Therefore, each of the permanent listeners should only have 1 usage per script!
 *
 * Do not set up message listeners in React hooks,
 * because it may lead to having multiple callbacks for one action type.
 */

const setupPermanentListener = (
    action: string,
    callback: (response: any) => void
) => {
    callbacks[action] = callback
}

export const onRecordFromContext = (
    callback: (response: { selection: string }) => void
) => setupPermanentListener('CONTEXT_MENU/RECORD_WITH_TAG', callback)

export const onRecordFromKeyboard = (callback: () => void) =>
    setupPermanentListener('KEYBOARD/RECORD_WITH_TAG', callback)

export const onRecordFromWebapp = (callback: () => void) => {
    setupPermanentListener('WEBAPP/LAUNCH_EXT_RECORDER', callback)
}

export const onRecordFromBackground = (callback: () => void) =>
    setupPermanentListener('BACKGROUND/LAUNCH_EXT_RECORDER', callback)

export const onSearchFromContext = (
    callback: (response: { selection: string }) => void
) => setupPermanentListener('CONTEXT_MENU/TRIGGER_SEARCH', callback)

export const onSearchFromKeyboard = (callback: () => void) =>
    setupPermanentListener('KEYBOARD/TRIGGER_SEARCH', callback)

export const onQGScreenshotRemote = (callback: () => void) =>
    setupPermanentListener('REMOTE/QG_SCREENSHOT', callback)

export const onPauseResumeRemote = (callback: () => void) =>
    setupPermanentListener('REMOTE/PAUSE_RESUME', callback)

export const onTriggerVideoPlayer = (
    callback: (response: { playbookId: string; isPlaylist: boolean }) => void
) => setupPermanentListener('TRIGGER_VIDEO_PLAYER', callback)

export const onRestartQG = (callback: () => void) =>
    setupPermanentListener('REMOTE/RESTART_QG', callback)

export const onRestartPB = (callback: () => void) =>
    setupPermanentListener('REMOTE/RESTART_PB', callback)

export const onServiceUsageUpdate = (
    callback: (response: ServicesUsageType) => void
) =>
    // establish listening for updates of service usage in background script
    // to get data in content script as soons as they were updated in db
    setupPermanentListener(
        PermanentListenerE.LISTEN_SERVICE_USAGE_UPDATE,
        callback
    )

export const onSetQuickAction = (callback: () => void) =>
    setupPermanentListener(
        PermanentListenerE.SET_QUICK_ACTION_LIB_MODE,
        callback
    )

export const onInsertQuickActionResult = (
    callback: (params: QuickActionResult) => void
) =>
    setupPermanentListener(
        PermanentListenerE.INSERT_QUICK_ACTION_RESULT,
        callback
    )

export const onQGStatusChange = (
    callback: (response: {
        newState: QG_STATE_UNION
        prevState: QG_STATE_UNION
    }) => void
) => setupPermanentListener(PermanentListenerE.QG_STATE_CHANGED, callback)
