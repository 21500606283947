import React from 'react'
import { Box, Divider, List, makeStyles } from '@material-ui/core'

import { InfoListItem } from './InfoListItem'
import { InfoItem } from './InfoTab'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: '75vh',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}))

type Props = {
    files?: Array<InfoItem>
    links?: Array<InfoItem>
}

export const InfoList = ({ files, links }: Props) => {
    const classes = useStyles()

    return (
        <List className={classes.root} subheader={<li />}>
            {!!links?.length && (
                <li className={classes.listSection}>
                    <ul className={classes.ul}>
                        {links.map(item => (
                            <Box key={item.id}>
                                <InfoListItem item={item} isLink />
                                <Divider />
                            </Box>
                        ))}
                    </ul>
                </li>
            )}
            {!!files?.length && (
                <li className={classes.listSection}>
                    <ul className={classes.ul}>
                        {files.map((item, idx) => (
                            <Box key={item.id}>
                                <InfoListItem item={item} />
                                {files[idx + 1] && <Divider />}
                            </Box>
                        ))}
                    </ul>
                </li>
            )}
        </List>
    )
}
