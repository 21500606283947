import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'

import StopIcon from '@material-ui/icons/Stop'
import PauseIcon from '@material-ui/icons/Pause'
import ReplayIcon from '@material-ui/icons/Replay'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { RecSettingsTitle } from './VideoRecorder/RecSettingsTitle'
import {
    addChapter,
    cancelScreenCapture,
    changeRecPanelMode,
    deleteQGRecording,
    pauseQGRecording,
    restartQGRecording,
    restartScreenCapture,
    resumeQGRecording,
    sendManualQGStep,
    stopQGRecordingNow,
    stopScreenCapture,
} from '../../background/services'
import { makeStyles, Typography } from '@material-ui/core'
import { QG_STATE, QG_STATE_UNION } from '../../types/quickGuidde'

type Props = {
    mode: 'quickGuidde' | 'playbook'
    qgState: QG_STATE_UNION | null
    closeModal: () => void
}
const getRecControls = (isQG: boolean, closeModal: () => void) => [
    {
        value: 'restart',
        label: 'Restart',
        icon: <ReplayIcon />,
        callback: isQG
            ? () => {
                  restartQGRecording()
              }
            : () => {
                  restartScreenCapture()
              },
    },
    {
        value: 'showPanel',
        label: 'Show panel',
        icon: <VisibilityIcon />,
        callback: () => {
            changeRecPanelMode({ mode: 'pageCircle' })
        },
    },
    {
        value: 'manualAction',
        label: isQG ? 'Screenshot' : 'Chapter',
        icon: <AddToQueueIcon />,
        callback: isQG
            ? () => {
                  closeModal()
                  sendManualQGStep(true)
              }
            : () => {
                  addChapter()
              },
    },
    {
        value: 'cancel',
        label: 'Delete',
        icon: <DeleteOutlineIcon />,
        callback: isQG
            ? () => {
                  deleteQGRecording()
              }
            : () => {
                  cancelScreenCapture()
              },
    },
]

const useStyles = makeStyles(theme => ({
    label: {
        display: 'flex',
        marginTop: theme.spacing(0.5),
        color: '#090C1099',
        fontSize: '12px',
        textAlign: 'center',
        '& svg': {
            height: theme.spacing(2),
            color: '#73CC6D',
        },
    },
    btn: {
        width: 'fit-content',
        backgroundColor: '#e3e3e3',
        '&:hover': {
            backgroundColor: '#dcdcdc',
        },
    },
    primaryBtn: {
        width: 'fit-content',
        color: '#ffffff',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))

const RecPanelBtn = ({ primary = false, icon, label, onClick }) => {
    const classes = useStyles()

    return (
        <Box
            mx={1}
            mb={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <IconButton
                className={primary ? classes.primaryBtn : classes.btn}
                onClick={onClick}
            >
                {icon}
            </IconButton>
            <Typography className={classes.label}>{label}</Typography>
        </Box>
    )
}

export const RecPanel = ({ mode, qgState, closeModal }: Props) => {
    const isQG: boolean = mode === 'quickGuidde'

    if (isQG && qgState === null) {
        return null
    }

    return (
        <>
            <Box pt={2}>
                <RecSettingsTitle />
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" px={5} py={2}>
                <RecPanelBtn
                    primary
                    label={
                        <>
                            <CheckCircleIcon color="inherit" /> Done
                        </>
                    }
                    icon={<StopIcon />}
                    onClick={
                        isQG
                            ? () => {
                                  stopQGRecordingNow(false)
                              }
                            : () => {
                                  stopScreenCapture({
                                      mxpMeta: {
                                          stopSource: 'iframeRecPanelStopBtn',
                                      },
                                  })
                              }
                    }
                />

                {isQG && (
                    <RecPanelBtn
                        label={qgState === QG_STATE.PAUSED ? 'Resume' : 'Pause'}
                        icon={
                            qgState === QG_STATE.PAUSED ? (
                                <PlayArrowIcon />
                            ) : (
                                <PauseIcon />
                            )
                        }
                        onClick={
                            qgState === QG_STATE.PAUSED
                                ? () => {
                                      resumeQGRecording()
                                  }
                                : () => {
                                      pauseQGRecording()
                                  }
                        }
                    />
                )}

                {getRecControls(isQG, closeModal).map(control => (
                    <RecPanelBtn
                        key={control.value}
                        icon={control.icon}
                        label={control.label}
                        onClick={control.callback}
                    />
                ))}
            </Box>
        </>
    )
}
