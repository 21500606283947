import React, { useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useBoolean, useOrgFeatureFlags, useStorageSetting } from 'hooks'

import { PlaybookSettings } from 'UI/Components/VideoRecorder/PlaybookSettings'
import { ServiceUsageController } from 'UI/Components'
import { AppContext } from 'app/AppProvider'
import { RootState } from 'ducks/rootReducer'

import LinearProgress from '@material-ui/core/LinearProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import { RecSettingsTitle } from './RecSettingsTitle'
import { DialogContent } from '@material-ui/core'
import { Actions } from './DialogActions'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'
import { WEBCAM_SETTING } from '../../../background/constants'
import { VideoOptions } from './VideoOptions'
import { QGSettings } from './QGSettings'
import { RecordingKeyboard } from './RecordingKeyboard'
import { RecSettingsFooter } from './RecSettingsFooter'

const useStyles = makeStyles(theme => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '20px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D8D8D8',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
        },
    },
    dialogTitle: {
        padding: theme.spacing(0.5),
        '& h2': {
            fontSize: '12px',
            fontWeight: 400,
            LineHeight: '16px',
            color: theme.palette.text.primary,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    description: {
        fontSize: '20px',
        fontWeight: 500,
    },
}))

export const VideoRecorder = ({ startCapture, onClose, onStartRecording }) => {
    const classes = useStyles()
    const recSettingsLoadingState = useBoolean(false)
    const { isFlagsLoading, showQGRecordOnly } = useOrgFeatureFlags()
    const {
        serviceUsage: { isRecordingLocked },
    } = useContext(AppContext)
    const { roles } = useSelector((state: RootState) => state.user)

    const isAdmin = roles.a === 100
    const isViewer = roles.a === 10

    const screenCamSetting = useStorageSetting(WEBCAM_SETTING)
    const isQG = screenCamSetting.storageSetting === 'stepByStep'

    useEffect(() => {
        if (showQGRecordOnly) {
            screenCamSetting.changeSetting('stepByStep')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showQGRecordOnly]) // Set default value on flag change

    if (isAdmin && isRecordingLocked) {
        return <ServiceUsageController onClose={onClose} />
    }

    const userCannotCreateContent = isRecordingLocked || isViewer

    return (
        <>
            {(!screenCamSetting.storageSetting ||
                isFlagsLoading ||
                recSettingsLoadingState.isTrue) && <LinearProgress />}

            <DialogTitle className={classes.dialogTitle}>
                <RecSettingsTitle />
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {screenCamSetting.storageSetting && !isFlagsLoading && (
                    <>
                        {showQGRecordOnly ? (
                            <span className={classes.description}>
                                Create how-to videos
                            </span>
                        ) : (
                            <VideoOptions screenCamSetting={screenCamSetting} />
                        )}

                        {/* QG settings are separated to avoid requesting mic/cam permissions */}
                        {isQG ? (
                            <QGSettings
                                startQGCapture={onStartRecording}
                                isRecordingLocked={userCannotCreateContent}
                            />
                        ) : (
                            <PlaybookSettings
                                isRecordingLocked={userCannotCreateContent}
                                loadingState={recSettingsLoadingState}
                                startCapture={startCapture}
                            />
                        )}

                        <RecordingKeyboard />
                    </>
                )}
            </DialogContent>

            <DialogActions className={classes.actions}>
                <Actions isQG={isQG} />
            </DialogActions>

            <RecSettingsFooter
                showQGRecordOnly={showQGRecordOnly}
                loadingState={recSettingsLoadingState}
                onClose={onClose}
            />
        </>
    )
}
