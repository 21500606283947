import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { Fade, Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { UseFilterState } from 'UI/Pages/playbooks/PlaybookFilters'
import { ScheduleSession } from './ScheduleSession'

import { logToAnalytics } from 'background/services'
import { uiId } from 'services/utils'
import { openRequestPbModal } from 'ducks/actions'

const useStyles = makeStyles(({ spacing, palette }) => ({
    bottomSection: {
        position: 'relative',
        width: '100%',
        background: 'white',
        flexShrink: 0,
        display: 'flex',
        boxSizing: 'border-box',
        overflow: 'hidden',
        alignItems: 'center',
        padding: `${spacing(2)}px ${spacing(3)}px`,
        boxShadow:
            '0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14)',
    },
    onTransitionEnd: {
        flexWrap: 'wrap',
        overflow: 'unset',
    },
    requestText: {
        minHeight: '32px',
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        marginRight: spacing(2),
        fontSize: '14px',
        letterSpacing: '0.1px',

        '$onTransitionEnd &': {
            flexShrink: 'unset',
        },
    },
    feedbackLink: {
        color: palette.secondary.main,
        '&, &:hover': {
            textDecoration: 'underline',
        },
        '&:hover': {
            background: 'none',
            color: '#00bcd4',
        },
    },
    playbooksTabWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    playbooksTabBody: {
        overflow: 'auto',
        flexGrow: 1,
    },
    leftSectionWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        '& > div': {
            flexShrink: 0,
            '$onTransitionEnd &': {
                flexShrink: 'unset',
            },
        },
    },
}))

type PlaybooksFooterProps = {
    searchText: string | null
    appFilter: UseFilterState<string>
    tagsFilter: UseFilterState<string[]>
}

export const PlaybooksFooter: React.FC<PlaybooksFooterProps> = ({
    searchText,
    appFilter,
    tagsFilter,
}) => {
    const [isTransitionFinished, setIsTransitionFinished] = useState(true)
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <Fade
            in
            timeout={300}
            onEntered={() => setIsTransitionFinished(true)}
            onEnter={() => setIsTransitionFinished(false)}
        >
            <Box
                className={clsx(
                    classes.bottomSection,
                    isTransitionFinished && classes.onTransitionEnd
                )}
            >
                <Typography className={classes.requestText}>
                    Couldn’t find what you were looking for?
                </Typography>
                <Box className={classes.leftSectionWrapper}>
                    <Box>
                        <Button
                            size="small"
                            id={uiId('request-a-playbook-btn')}
                            onClick={() => {
                                logToAnalytics('request_playbook_btn_clicked')
                                dispatch(
                                    openRequestPbModal({
                                        subject: searchText,
                                        applicationId:
                                            appFilter.state ||
                                            appFilter.defaultState,
                                        tags:
                                            tagsFilter.state.length > 0
                                                ? tagsFilter.state
                                                : tagsFilter.defaultState,
                                    })
                                )
                            }}
                        >
                            Ask a Guidde
                        </Button>
                    </Box>
                    <ScheduleSession />
                </Box>
            </Box>
        </Fade>
    )
}
