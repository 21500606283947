import {
    FormControl,
    FormHelperText,
    makeStyles,
    TextField,
    TextFieldProps,
} from '@material-ui/core'
import { FastField } from 'formik'

const useStyles = makeStyles({
    input: {
        boxShadow: 'none',
        border: 'none',
        '&:hover, &:active, &:focus': {
            background: 'transparent',
            boxShadow: 'none',
        },
    },
})

type Props = {
    name: string
    required?: boolean
    placeholder?: string
    fullWidth?: boolean
    type?: 'text'
    multiline?: boolean
    minRows?: number
    width?: string
    autoFocus?: boolean
    disabled?: boolean
    style?: any
    disableUnderline?: boolean
    variant?: TextFieldProps['variant']
    inputProps?: TextFieldProps['inputProps']
    [key: string]: any
}

export const ConnectedInput = ({
    name,
    required = false,
    placeholder,
    fullWidth,
    type = 'text',
    multiline,
    minRows,
    width,
    autoFocus,
    disabled,
    style,
    disableUnderline,
    variant,
    inputProps,
    ...textFieldProps
}: Props) => {
    const classes = useStyles()

    const InputProps = {
        disableUnderline: disableUnderline,
        className: classes.input,
    }

    return (
        <FastField name={name}>
            {({ field, meta }) => (
                <FormControl fullWidth={fullWidth}>
                    <TextField
                        {...textFieldProps}
                        {...field}
                        inputProps={inputProps}
                        InputProps={variant === 'outlined' ? {} : InputProps}
                        variant={variant}
                        type={type}
                        required={required}
                        placeholder={placeholder}
                        onBlur={field.onBlur}
                        value={field.value || ''}
                        error={meta.touched && meta.error !== undefined}
                        style={{
                            ...style,
                            ...(fullWidth ? {} : { width: width ?? '300px' }),
                            padding: '6px 0 7px !important',
                        }}
                        multiline={multiline}
                        minRows={minRows}
                        maxRows={5}
                        autoComplete="off"
                        autoFocus={autoFocus}
                        disabled={disabled}
                        onKeyPress={e => {
                            const key = e.key
                            const forbiddenChars = ['<', '>']

                            if (forbiddenChars.includes(key)) {
                                e.preventDefault()
                            }
                        }}
                    />
                    {meta.touched && meta.error !== undefined && (
                        <FormHelperText>{meta.error}</FormHelperText>
                    )}
                </FormControl>
            )}
        </FastField>
    )
}
