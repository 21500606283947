export enum FirebaseRequestE {
    GET_FB_USER = 'getFirebaseUser',
    GET_USER_SPACES = 'getUserSpaces',
    GET_APPS = 'getApps',
    GET_ORG_DATA = 'getOrganizationData',
    GET_GLOBAL_FEATURE_FLAG_DATA = 'getGlobalFeatureFlagData',
    GET_NOTIFICATIONS = 'getNotifications',
    MARK_NOTIFICATIONS_AS_READ = 'markAllNotificationsAsRead',
    REMOVE_NOTIFICATION = 'removeNotification',
    GET_ORG_USERS = 'getOrgUsers',
    GET_TAG_CONDITIONS = 'getTagConditions',
    GET_TRANSCRIPT = 'getTranscript',
    GET_USER_ACHIEVEMENTS = 'getUserAchievements',
    GET_SERVICE_USAGE = 'getServiceUsage',
}

export enum GuiddeRequestE {
    GET_PLAYBOOKS = 'getPlaybooks',
    GET_PLAYBOOK = 'getPlaybook',
    GET_PLAYBOOK_LIST = 'getPlaybookList',
    REQUEST_PLAYBOOK = 'requestPlaybook',
    SHARE_PLAYBOOK_ONLY = 'sharePlaybookOnly',
    SEND_INVITE = 'sendInvite',
    GET_PLAYBOOK_STEPS = 'getPlaybookSteps',
    GET_PLAYBOOK_OPTIONS = 'getPlaybookOptions',
    LOG_TO_ANALYTICS = 'logToAnalytics',
    SEND_VIEW_NOTIFICATION = 'sendViewNotification',
    GET_PB_REQUEST_DEFAULTS = 'getPbRequestDefaults',
    GET_QUICK_ACTION_CONFIG = 'getQuickActionConfig',
    GET_MAGIC_CAPTURE_CONFIG = 'getMagicCaptureConfig',
    TRIGGER_FILE_UPLOAD = 'triggerFileUpload',
    PLAYBOOK_META_UPLOAD = 'playbookMetaUpload',
}

export enum PlaybookRecordingE {
    START_PLAYBOOK = 'startScreenCapture',
    CANCEL_PLAYBOOK = 'cancelScreenCapture',
    RESTART_PLAYBOOK = 'restartScreenCapture',
    STOP_PLAYBOOK = 'stopScreenCapture',
    GET_REC_STATUS = 'getRecordingStatus',
    SUBSCRIBE_TO_REC_STATUS = 'subscribeToRecStatus',
    GET_REC_START_TIME = 'getRecordingStartTime',
    START_COUNTDOWN_AND_WEBCAM = 'startCountdownAndWebcam',
    UPDATE_REC_STATUS = 'updateRecordingStatus',
    START_REC_OBSERVER = 'startRecObserver',
    STOP_REC_OBSERVER = 'stopRecObserver',
    ADD_NEW_CHAPTER = 'addNewChapter',
}

export enum ExtensionFunctionalityE {
    TRIGGER_PLAYER = 'triggerVideoPlayer',
    OPEN_EXTERNAL_LINK = 'openExternalLink',
    OPEN_LIBRARY = 'openLibrary',
    OPEN_REC_IFRAME = 'openRecIframe',
    GET_DEVICES = 'getAvailableDevices',
    GET_EXT_ID = 'getExtensionId',
    GET_PLAY_MSG = 'getPlayMessage',
    OPEN_IN_APP = 'openInApp',
    UPDATE_CONTEXT_MENU = 'updateContextMenu',
    GET_KEYBOARD_SHORTCUTS = 'getKeyboardShortcuts',
    INSERT_QA_RESULT = 'insertQuickActionResult',
    IS_REC_IN_PROGRESS = 'isRecInProgress',
    GET_QG_PERMISSIONS = 'getQGPermissions',
    CHANGE_REC_PANEL_MODE = 'changeRecPanelLocation',
}

export enum QuickGuiddeFunctionalityE {
    GET_QG_STATE = 'getQGRecordingState',
    HANDLE_RESIZE = 'handleResizeEvent',
    GET_RESIZE_METADATA = 'getResizeMetaData',
    GET_STEP_TEXT_LIMIT = 'getStepTextLimit',
    DELETE_QG = 'deleteQGRecording',
    RESTART_QG = 'restartQGRecording',
    START_QG = 'startQGRecording',
    STOP_QG_NOW = 'stopQGRecordingNow',
    STOP_QG = 'stopQGRecording',
    STOP_QG_AFTER_CHANGE_STEP = 'stopQGRecordingAfterChangeStep',
    SEND_MANUAL_QG_STEP = 'sendManualQGStep',
    ESTABLISH_NEW_CONNECTION = 'establishNewConnection',
    GET_LAST_STEP_URL_BY_INDEX = 'getLastStepUrlByIndex',
    PAUSE_QG = 'pauseQGRecording',
    RESUME_QG = 'resumeQGRecording',
    PAUSE_RESUME_QG = 'pauseResumeQGRecording',
}

export enum SdkBackgroundAPIE {
    SDK_SEARCH = 'searchFromSdkApi',
}

export type BackgroundAPIMsg =
    | `${FirebaseRequestE}`
    | `${GuiddeRequestE}`
    | `${PlaybookRecordingE}`
    | `${ExtensionFunctionalityE}`
    | `${QuickGuiddeFunctionalityE}`
    | `${SdkBackgroundAPIE}`
