import { checkIsExtension } from './environmentService'
import { captureSentryError } from './sentry'

/**
 * Converts a relative path to a resource to a path depending on the context,
 * in which the resource is loaded.
 * @param {string} path A relative path to a resource
 * @example
 * import logo from "./logo.svg";
 *
 * function ImageComponent() {
 *   return (
 *     <img
 *       src={EXT_SERVICES.getResourceURL(logo)}
 *       alt="logo"
 *     />
 *   );
 * }
 *
 */
export const getResourceURL = (path: string) => {
    if (checkIsExtension()) {
        return chrome?.runtime?.getURL(path)
    }

    return path
}

/* Chrome storage ops */

export const setToExtLocalStorage = (
    key: string,
    value: any,
    onSetKeys: () => any = () => {}
) => {
    chrome?.storage?.local.set({ [key]: value }, onSetKeys)

    // Because chrome storage won't work on localhost
    if (process.env.NODE_ENV === 'development') {
        onSetKeys?.()
    }
}

export const getFromExtLocalStorage = (
    keys: string[],
    onGetKeys: (result: any) => void
) => {
    chrome?.storage?.local.get(keys, onGetKeys)

    // Because chrome storage won't work on localhost
    if (process.env.NODE_ENV === 'development') {
        onGetKeys?.({})
    }
}

export const onExtLocalStorageChange = (
    key,
    onChange: (newValue: any) => void
) => {
    chrome?.storage?.onChanged.addListener((storage, storageType) => {
        if (storageType !== 'local' || !storage[key]) {
            return
        }

        onChange(storage[key].newValue)
    })
}

export const getExtensionVersion = () =>
    chrome?.runtime?.getManifest?.()?.version || ''

const KNOWN_ERRORS = [
    'Could not establish connection. Receiving end does not exist.',
    'The message port closed before a response was received.',
    'A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received',
]
export const handleLastError = (errorMessage: string | undefined) => {
    if (!errorMessage) return

    if (KNOWN_ERRORS.includes(errorMessage)) {
        return
    }

    captureSentryError(chrome.runtime.lastError)
}
