import { useMemo, useEffect, useState, memo, useContext } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core'

import { RootState } from 'ducks/rootReducer'
import { getPlaybooks } from 'background/services'
import { uiId } from 'services/utils'

import { FilterSelectMultiple } from 'UI/Components'
import SpacesIcon from 'assets/icons/icon-spaces.svg'
import { isEmpty } from '../../../../services/lodash'
import { AppContext } from '../../../../app/AppProvider'

const useStyles = makeStyles(theme => ({
    labelIcon: {
        width: '20px',
        height: '20px',
        marginRight: theme.spacing(0.5),
    },
}))

type Props = {
    onUpdate: any
    value: string[]
}

export const generateSpaceOptions = (teamIds, userSpaces) => {
    if (
        !Object.keys(teamIds || {}).length ||
        !Object.keys(userSpaces || {}).length
    )
        return []

    const spaceIds = Object.keys(teamIds)

    const options = spaceIds
        .map(spaceId => {
            const name = userSpaces[spaceId]?.name
            return name ? { value: spaceId, label: name } : null
        })
        .filter(Boolean)

    return options.sort((a, b) => {
        if (!a || !b) return -1
        return a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1
    })
}

export const SpaceFilter = memo(({ onUpdate, value }: Props) => {
    const classes = useStyles()
    const { isLibraryOpened } = useContext(AppContext)

    const [teamIds, setTeamIds] = useState({})
    const userSpaces = useSelector((state: RootState) => state.spaces)

    const noTeamIds: boolean = isEmpty(teamIds)

    useEffect(() => {
        if (isLibraryOpened && noTeamIds) {
            getPlaybooks(data => {
                if (!data) return

                setTeamIds(data.facets.teamIds)
            }, {})
        }
    }, [isLibraryOpened, noTeamIds])

    const spaceOptions = useMemo(
        // we should use teamIds instead of key name 'spaces',
        // because the backend should do many migrations for adding key name 'spaces'
        () => generateSpaceOptions(teamIds, userSpaces),
        [teamIds, userSpaces]
    )

    return (
        <FilterSelectMultiple
            value={value}
            onChange={onUpdate}
            options={spaceOptions}
            id={uiId('filter-spaces')}
            labelIcon={
                <img
                    className={classes.labelIcon}
                    src={SpacesIcon}
                    alt="spaces"
                />
            }
            multipleItemsLabel="spaces"
        />
    )
})
