// Global vars, that are automatically resolved in mixpanel logs (analytics, info-only)
import { QG_STATE_UNION } from '../types/quickGuidde'

declare global {
    interface Window {
        _GUIDDE: {
            CURRENT_APP: string
            CURRENT_DOMAIN: string
            DEFAULT_TAGS: string[]
        }
        QG_UTILS: {
            ON_STATE_CHANGED: (state: QG_STATE_UNION) => void
        }
    }
}

export const playbookToAnalyticsProps = playbook => ({
    'pb-app': playbook.applicationName,
    'pb-appId': playbook.applicationId,
    'pb-tags': playbook.tags,
    'pb-id': playbook.id,
    'pb-title': playbook.title,
    'pb-visibility': playbook.visibility,
    'pb-creator-org-id': playbook.uploadedByOrgId,
    'pb-creator-name': playbook.username,
})
