import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'

import { makeStyles } from '@material-ui/core/styles'
import { useBoolean, useOrgFeatureFlags, useStorageSetting } from 'hooks'
import { MagicCaptureBanner } from './MagicCaptureBanner'
import { MagicCaptureDialog } from './MagicCaptureDialog'

import { MAGIC_CAPTURE_ENABLED } from 'background/constants'
import { DialogAlerts } from './DialogAlerts'
import { PermissionStatusE } from '../../../types'
import { useEffect, useState } from 'react'
import { getAllSitePermissions } from '../../../background/services'

const BTN_SIZE = 90
const RING_SIZE = 30
const MIN_RING_WIDTH = 8

const useStyles = makeStyles(theme => ({
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        transition: 'width 0.3s, height 0.3s',
    },
    outerRing: {
        width: `${BTN_SIZE + 2 * RING_SIZE}px`,
        height: `${BTN_SIZE + 2 * RING_SIZE}px`,
        backgroundColor: 'rgba(203, 0, 0, 0.1)',
    },
    disabled: {
        pointerEvents: 'none',
        filter: 'grayscale(1)',
        opacity: '0.7',
    },
    middleRing: {
        width: `${BTN_SIZE + RING_SIZE}px`,
        height: `${BTN_SIZE + RING_SIZE}px`,
        backgroundColor: 'rgba(203, 0, 0, 0.1)',
    },
    innerCircle: {
        width: `${BTN_SIZE}px`,
        height: `${BTN_SIZE}px`,
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        fontWeight: 500,
        fontSize: '14px',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    middleHover: {
        width: `${BTN_SIZE + 2 * RING_SIZE - MIN_RING_WIDTH}px`,
        height: `${BTN_SIZE + 2 * RING_SIZE - MIN_RING_WIDTH}px`,
    },
    innerHover: {
        width: `${BTN_SIZE + 2 * RING_SIZE - 2 * MIN_RING_WIDTH}px`,
        height: `${BTN_SIZE + 2 * RING_SIZE - 2 * MIN_RING_WIDTH}px`,
    },
    statusLabel: {
        marginTop: theme.spacing(2),
        color: 'rgba(9, 12, 16, 0.5)',
        fontSize: '12px',
    },
    lockedBtnWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '12px',

        '& svg': {
            marginBottom: theme.spacing(1),
        },
    },
}))

type QGTextProps = {
    startQGCapture: () => void
    isRecordingLocked: boolean
}

export const QGSettings = ({
    startQGCapture,
    isRecordingLocked,
}: QGTextProps) => {
    const classes = useStyles()
    const isHovered = useBoolean(false)
    const showMagicCaptureSettings = useBoolean(false)
    const [isQGAllowed, setQGPermissions] = useState<boolean | undefined>(
        undefined
    )

    let { showQGRecordOnly, allowMagicCapture, showMagicCaptureLanguages } =
        useOrgFeatureFlags()
    const { storageSetting: magicCapture, changeSetting: changeMC } =
        useStorageSetting(MAGIC_CAPTURE_ENABLED)

    useEffect(() => {
        getAllSitePermissions(isAllowed => {
            setQGPermissions(isAllowed)
        })
    }, [])

    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            mt={2}
            mb={1}
        >
            {isQGAllowed === false && (
                <DialogAlerts permissionStatus={PermissionStatusE.QG_BLOCKED} />
            )}

            {allowMagicCapture && (
                <MagicCaptureBanner
                    showQGRecordOnly={showQGRecordOnly}
                    magicCapture={magicCapture}
                    changeMC={changeMC}
                />
            )}

            <div
                className={clsx(classes.circle, classes.outerRing, {
                    [classes.disabled]: isQGAllowed === false,
                })}
                onMouseEnter={() => {
                    isHovered.setTrue()
                }}
                onMouseLeave={() => {
                    isHovered.setFalse()
                }}
            >
                <div
                    className={clsx(classes.circle, classes.middleRing, {
                        [classes.middleHover]: isHovered.isTrue,
                    })}
                >
                    <button
                        className={clsx(classes.circle, classes.innerCircle, {
                            [classes.innerHover]: isHovered.isTrue,
                        })}
                        onClick={() => {
                            if (isRecordingLocked) return

                            if (!isQGAllowed) return

                            if (allowMagicCapture && magicCapture) {
                                showMagicCaptureSettings.setTrue()
                                return
                            }

                            startQGCapture()
                        }}
                    >
                        {isRecordingLocked ? (
                            <Box className={classes.lockedBtnWrapper}>
                                <LockIcon />
                                locked
                            </Box>
                        ) : (
                            `CAPTURE`
                        )}
                    </button>
                </div>
            </div>
            {isRecordingLocked && (
                <Typography className={classes.statusLabel}>
                    Contact your admin to unlock
                </Typography>
            )}

            {showMagicCaptureSettings.isTrue && (
                <MagicCaptureDialog
                    startQG={startQGCapture}
                    onClose={showMagicCaptureSettings.setFalse}
                    showMagicCaptureLanguages={Boolean(
                        showMagicCaptureLanguages
                    )}
                />
            )}
        </Box>
    )
}
