import { initReact } from 'initReact'
import { registerSDKToFirebaseAuth } from './firebase/auth'

import { registerAnalyticsSuperProps } from './background/analytics'

import { setSdkConfig } from './ducks/actions'
import { store } from './ducks/store'

import { RESERVED_USER_VARS } from './parent/utils'
import { isLocal } from './services/environmentService'
import Config from './config'
import { consoleSilencer } from './services/consoleSilencer'
import { captureSentryError } from './services/sentry'

let inactive = true

const addMixpanelPrefix = userInfo =>
    userInfo
        ? Object.entries(userInfo).reduce(
              (acc, [key, value]) =>
                  // Add 'tenant_' prefix to non-standard vars, so they don't override our mixpanel properties
                  RESERVED_USER_VARS.has(key)
                      ? { ...acc, [key]: value }
                      : { ...acc, [`tenant_${key}`]: value },
              {}
          )
        : userInfo

const onAuthMessageReceived = async event => {
    const {
        type,
        tenant,
        session,
        apiKey,
        userInfo,
        organizationId,
        styles,
        embed,
    } = event?.data

    if (type === 'INIT_SDK' && inactive && tenant && session) {
        if (isLocal) {
            // @ts-ignore - set up proxy for local env
            window.localProxy = `https://${apiKey}.sdk.${Config.firebase.authDomain}`
        }

        consoleSilencer(userInfo?.debug === 'true')

        registerSDKToFirebaseAuth(
            tenant,
            apiKey,
            session,
            userInfo,
            firebaseUser => {
                if (!firebaseUser) {
                    captureSentryError(new Error('Auth error'), firebaseUser)
                }

                registerAnalyticsSuperProps(
                    {
                        ...firebaseUser,
                        customProps: addMixpanelPrefix(userInfo),
                    },
                    {
                        'sdk-tenant': tenant,
                        'sdk-org': organizationId,
                    }
                )
                initReact()
                window.removeEventListener('message', onAuthMessageReceived)
                store.dispatch(setSdkConfig({ ...styles, embed }))
            }
        )
        inactive = false
    }
}

const initSDKContentScript = () => {
    window.addEventListener('message', onAuthMessageReceived, false)
}

export default initSDKContentScript
