import { useSelector } from 'react-redux'

import { option, uiId } from 'services/utils'
import { RootState } from 'ducks/rootReducer'

import { makeStyles } from '@material-ui/core'
import { FilterSelectMultiple } from 'UI/Components'
import IconApps from 'assets/icons/icon-apps.svg'

const useStyles = makeStyles(theme => ({
    labelIcon: {
        marginRight: theme.spacing(0.5),
        width: '20px',
        height: '20px',
    },
}))

type Props = {
    value: string
    onUpdate: any
}

export const ApplicationsFilter = ({ value, onUpdate }: Props) => {
    const classes = useStyles()

    const appOptions: {
        value: string
        label: string
    }[] = useSelector((state: RootState) =>
        state.apps?.map(app => option(app.id, app.title))
    )

    return (
        <FilterSelectMultiple
            value={value}
            onChange={onUpdate}
            id={uiId('application-filter')}
            options={[
                ...appOptions?.sort((a, b): number =>
                    a.label > b.label ? 1 : -1
                ),
            ]}
            labelIcon={
                <img className={classes.labelIcon} src={IconApps} alt="app" />
            }
            multipleItemsLabel="applications"
        />
    )
}
