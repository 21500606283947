import {
    Dialog,
    makeStyles,
    Theme,
    createStyles,
    Box,
    IconButton,
    Divider,
} from '@material-ui/core'

import almostThere from 'assets/images/almostThere.png'
import React from 'react'
import { SpacedGroup } from './SpacedGroup'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import { useParentLocation } from '../../hooks'

type tabSelectionDialogProps = {
    url: string
    onClose: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 700,
            fontSize: '34px',
            lineHeight: '42px',
            textAlign: 'center',
        },
        body: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: 0.15,
            textAlign: 'center',
            color: 'rgba(9, 12, 16, 0.6)',
        },
        divider: {
            background: ' 1px solid rgba(9, 12, 16, 0.08)',
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
    })
)

export const AlmostThereDialog = ({
    onClose,
    url,
}: tabSelectionDialogProps) => {
    const classes = useStyles()

    const location = useParentLocation()

    if (!location) return null

    return (
        <Dialog open={true} fullWidth maxWidth="sm" onClose={onClose}>
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
            <SpacedGroup flexDirection="column" id="container">
                <Box p={4} id="containerBox">
                    <Box
                        className={classes.title}
                        mt={2}
                        mb={4.5}
                        id="headerText"
                    >
                        You are almost there!
                    </Box>

                    <Box style={{ textAlign: 'center' }}>
                        <img
                            alt="logo"
                            title="Capture steps"
                            src={almostThere}
                        />
                    </Box>

                    <Box className={classes.body} mt={3} id="bodyText">
                        Let us personalise your experience
                    </Box>
                    <Box className={classes.body}>in a few clicks</Box>
                </Box>
                <Box py={1} width="100%">
                    <Divider variant="fullWidth" className={classes.divider} />
                </Box>
                <Box my={2}>
                    <Button
                        onClick={() => {
                            location?.href.includes('onboarding')
                                ? onClose()
                                : window.open(url, '_blank')
                        }}
                    >
                        <Box
                            style={{
                                letterSpacing: 1.25,
                            }}
                        >
                            LET'S GO!
                        </Box>
                    </Button>
                </Box>
            </SpacedGroup>
        </Dialog>
    )
}
