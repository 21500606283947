import { memo } from 'react'

import {
    Dialog,
    IconButton,
    Typography,
    makeStyles,
    Button,
    Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
    paper: {
        width: '650px',
        height: '650px',
        boxShadow: 'none',
        background: 'transparent',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        height: '500px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 5,
        paddingTop: theme.spacing(3),
    },
    image: {
        width: '240px',
        height: '175px',
    },
    title: {
        fontWeight: theme.typography.fontWeightBold as number,
        fontSize: '24px',
        textAlign: 'center',
        lineHeight: 1.5,
    },
    secondaryAction: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#001a36',
        marginTop: theme.spacing(2),
    },
    close: {
        position: 'absolute',
        right: '50px',
        top: '30px',
        background: '#959B9E',
        borderRadius: '50%',
    },
    bubble: {
        position: 'absolute',
        borderRadius: '50%',
        zIndex: 1,
        transform: 'translateX(50%)',
    },
    wrapper: {
        height: '500px',
        width: '500px',
        position: 'relative',
    },
    primaryAction: {
        padding: theme.spacing(1, 4),
        borderRadius: '20px',
    },
}))

const bubbles = [
    {
        top: '105px',
        right: '40px',
        width: '125px',
        height: '125px',
        background: 0,
    },
    {
        top: '185px',
        right: '45px',
        width: '225px',
        height: '225px',
        background: 1,
        zIndex: 2,
    },
    {
        bottom: '-10px',
        right: '115px',
        width: '125px',
        height: '125px',
        background: 0,
    },
    {
        bottom: 0,
        left: '-42px',
        width: '125px',
        height: '125px',
        background: 1,
    },
    {
        bottom: '155px',
        left: '-55px',
        width: '60px',
        height: '60px',
        background: 0,
    },
    {
        top: '75px',
        left: '-150px',
        width: '155px',
        height: '155px',
        background: 0,
        zIndex: 2,
    },
    {
        top: '20px',
        left: '-30px',
        width: '115px',
        height: '115px',
        background: 0,
    },
    {
        top: '-65px',
        left: '36px',
        width: '210px',
        height: '210px',
        background: 1,
    },
]

type Props = {
    onClose: () => void
    isOpen: boolean
    image: string
    title: string
    text: JSX.Element
    onPrimaryClick: () => void
    primaryText: string
    secondaryButton?: JSX.Element
    bubblesBackground: Array<string>
    primaryButtonMt?: number
    imageMt?: number
    maxTextWidth?: string
}

export const DialogBody = memo(
    ({
        onClose,
        isOpen,
        image,
        title,
        text,
        onPrimaryClick,
        primaryText,
        secondaryButton,
        bubblesBackground,
        primaryButtonMt = 0,
        imageMt = 0,
        maxTextWidth = '310px',
    }: Props) => {
        const classes = useStyles()

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth
                maxWidth="md"
                classes={{
                    paper: classes.paper,
                }}
            >
                <Box className={classes.close}>
                    <IconButton
                        style={{
                            color: '#fff',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon color="inherit" />
                    </IconButton>
                </Box>
                <Box className={classes.wrapper}>
                    <Box className={classes.content}>
                        <Box mt={imageMt}>
                            <img src={image} className={classes.image} />
                        </Box>
                        <Box maxWidth={maxTextWidth} mb={2}>
                            <Typography className={classes.title}>
                                {title}
                            </Typography>
                            <Box mt={1} />
                            <Typography variant="subtitle2" align="center">
                                {text}
                            </Typography>
                        </Box>
                        <Box mt={primaryButtonMt}>
                            <Button
                                className={classes.primaryAction}
                                onClick={() => {
                                    onPrimaryClick()
                                    onClose()
                                }}
                            >
                                {primaryText}
                            </Button>
                        </Box>

                        {secondaryButton}
                    </Box>
                    {bubbles.map((style, i) => (
                        <Box
                            key={i}
                            style={{
                                ...style,
                                background: bubblesBackground[style.background],
                            }}
                            className={classes.bubble}
                        />
                    ))}
                </Box>
            </Dialog>
        )
    }
)
