import { combineReducers } from 'redux'

import {
    appsReducer,
    playbookReducer,
    playlistReducer,
    requestPbReducer,
    screenCaptureReducer,
    tagsReducer,
    userReducer,
    spaceReducer,
    sdkConfigReducer,
} from './reducers'

export const rootReducer = combineReducers({
    user: userReducer,
    playbook: playbookReducer,
    playlist: playlistReducer,
    apps: appsReducer,
    tags: tagsReducer,
    screenCapture: screenCaptureReducer,
    requestPb: requestPbReducer,
    spaces: spaceReducer,
    sdkConfig: sdkConfigReducer, // @TODO  we don't need this reducer for ext at all
})

export type RootState = ReturnType<typeof rootReducer>
