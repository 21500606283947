import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { openLibrary } from '../../../background/services'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    footer: { padding: theme.spacing(1.5, 4) },
    qGFooter: { backgroundColor: '#EDEEEF' },
    regularFooter: { backgroundColor: '#ffffff' },
}))

export const RecSettingsFooter = ({
    showQGRecordOnly,
    loadingState,
    onClose,
}) => {
    const classes = useStyles()
    return (
        <Box
            className={clsx(
                classes.footer,
                showQGRecordOnly ? classes.qGFooter : classes.regularFooter
            )}
        >
            <Button
                fullWidth
                disableElevation
                color="default"
                onClick={() => {
                    loadingState.setTrue()

                    openLibrary(
                        () => {
                            loadingState.setFalse()
                            onClose()
                        },
                        { preOpen: true }
                    )
                }}
            >
                <Typography
                    color="textSecondary"
                    style={{ letterSpacing: '1.25px' }}
                >
                    QUICK SEARCH
                </Typography>
            </Button>
        </Box>
    )
}
