const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyATarjLOqtsa2O2VJqtnv81Dje5REI2TYc',
    authDomain: 'app.guidde.com',
    databaseURL: 'https://guidde-production-default-rtdb.firebaseio.com',
    projectId: 'guidde-production',
    storageBucket: 'guidde-production.appspot.com',
    messagingSenderId: '484452355157',
    appId: '1:484452355157:web:2c5a764b5d360dbef6d8dc',
    measurementId: 'G-TZ133YV24B',
    environment: 'production',
}
// prettier-ignore
const Config = Object.freeze({
    firebase: FIREBASE_CONFIG,
    idKey: '',
})
export default Config
