import { useContext, memo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Box, Grid } from '@material-ui/core'

import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import GifIcon from '@material-ui/icons/Gif'

import { logToAnalytics, openExternalLink } from 'background/services'
import { playbookToAnalyticsProps } from 'services/analytics'
import { sendMessageToParentScript } from 'services/parentService'
import { copyGif } from 'services/copyGif'

import { PlaybookCard } from '@guidde-co/shared.playbook-card'
import { MenuItemType } from '@guidde-co/shared.playbook-card/playbook-card'

import { AppContext } from 'app/AppProvider'
import { PlaybookType } from 'types/playbook'

import { RootState } from 'ducks/rootReducer'

import { useBoolean } from 'hooks'

import { ShareDialog } from 'UI/Components'

export const generatePlaybook = (o): PlaybookType => ({
    url: o.url,
    screenshotUrl: o.img,
    creator_uid: o.creator_uid,
    duration: o.duration,
    id: o.id,
    username: o.creator_name,
    userImage: o.creator_img,
    uploadedAt: o.created,
    title: o.title,
    description: o.description,
    tags: o.tags,
    transcript: o.transcript || ' ',
    transcriptUrl: o.transcriptUrl || ' ',
    rating: +(o.rating || 0).toFixed(1),
    type: o.type,
    mode: o.mode,
    steps: o.steps || [],
    isPlaylist: o.isPlaylist,
    playbookList: o.playbookList,
    isPublished: !o.private,
    views: o.views || 0,
    applications: o.applications,
    files: o.files,
    links: o.links,
    visibility: o.visibility,
    currentTranscriptLang: o.currentTranscriptLang,
    isPublic: o.isPublic,
    uploadedByOrgId: o.uploadedByOrgId,
    orgName: o.orgName,
    gifUrl: o.gifUrl,
    teams: o.teams,
    publishedForUsers: o.publishedForUsers,
    organizationId: o.organizationId,
    originalFileName: o.originalFileName,
    isMainIncluded: o.isMainIncluded,
    coverColor: o.coverColor,
})

type PlaybookHitsProps = { playbooks: Array<PlaybookType> }

type MenuItemsType = Array<MenuItemType & { hidden?: boolean }>

export const PlaybookHits = memo(({ playbooks }: PlaybookHitsProps) => {
    const { openVideoPlayer } = useContext(AppContext)

    const showShareDialog = useBoolean()

    const { user, roles } = useSelector((state: RootState) => state.user)

    const [activePlaybook, setActivePlaybook] = useState<PlaybookType | null>(
        null
    )

    const isAdmin = roles?.a === 100
    const isContentManager = roles?.a === 60

    const menuItems = SDK_MODE
        ? () => []
        : (playbook: PlaybookType): MenuItemsType => {
              const isOwner = playbook?.creator_uid === user.uid

              const hasEditRights =
                  (isAdmin || isContentManager) &&
                  roles?.o === playbook.uploadedByOrgId

              const canEdit = isOwner || roles?.s || hasEditRights

              return [
                  {
                      title: 'Share',
                      icon: ShareIcon,
                      onClick: () => {
                          setActivePlaybook(playbook)
                          showShareDialog.toggle()
                          logToAnalytics(
                              playbook.isPlaylist
                                  ? 'share_playlist_btn_clicked'
                                  : 'share_pb_btn_clicked',
                              playbookToAnalyticsProps(playbook)
                          )
                      },
                  },
                  {
                      title: 'Copy Linked GIF',
                      icon: GifIcon,
                      onClick: () => {
                          copyGif({ playbook, uid: user.uid }).then(() => {
                              logToAnalytics('copyLink', {
                                  context: 'copy-linked-gif',
                                  ...playbookToAnalyticsProps(playbook),
                              })
                              sendMessageToParentScript({
                                  action: 'CLOSE_IFRAME',
                              })
                          })
                      },
                      showDivider: canEdit,
                  },
                  {
                      title: 'Edit',
                      icon: EditIcon,
                      onClick: () => {
                          openExternalLink(null, {
                              url: `playbooks${
                                  playbook.isPlaylist ? '/playlist' : ''
                              }/${playbook.id}/edit`,
                          })
                      },
                      hidden: !canEdit,
                  },
              ].filter(it => !it.hidden)
          }

    const handlePlay = (playbook: PlaybookType) => {
        openVideoPlayer(playbook)
        logToAnalytics('videoPlayClicked', playbookToAnalyticsProps(playbook))
    }

    if (!playbooks?.length) return null

    return (
        <Box mt={2}>
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                style={{ flexDirection: 'row' }}
            >
                {playbooks.map(hit => {
                    const playbook = generatePlaybook(hit)

                    const playbookCard = o => ({ ...o })

                    return (
                        <Grid
                            item
                            sm={6}
                            md={3}
                            key={playbook.id}
                            style={{
                                width: '100%',
                            }}
                        >
                            <PlaybookCard
                                onPlayClick={() => handlePlay(playbook)}
                                playbook={playbookCard(playbook)}
                                menuItems={menuItems(playbook)}
                                zIndexMenu={100000}
                            />
                        </Grid>
                    )
                })}
            </Grid>
            {showShareDialog.isTrue && activePlaybook && (
                <ShareDialog
                    onClose={showShareDialog.toggle}
                    playbook={activePlaybook}
                />
            )}
        </Box>
    )
})
