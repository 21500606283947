import { useState, memo } from 'react'
import { useSelector } from 'react-redux'

import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'

import { RootState } from 'ducks/rootReducer'
import { InfoTab } from './tabs'
import { Transcript } from 'UI/Components/Transcript/Transcript'
import theme from 'app/theme'
import { PlaybookType } from 'types'

const useStyles = makeStyles({
    tab: {
        minWidth: '140px',
    },
    tabPanel: {
        '& > div': {
            padding: theme.spacing(0, 3, 3, 3),
        },
    },
    sidePanelContainer: {
        maxHeight: 'calc(100vh - 10px)',
        overflow: 'hidden',
    },
})

const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <Box hidden={value !== index} {...other}>
            <Box p={3}>{children}</Box>
        </Box>
    )
}

type Props = {
    playbook: PlaybookType
    startTime?: number
    transcript?: any
}

export const SidePanel = memo(({ startTime, transcript, playbook }: Props) => {
    const classes = useStyles()
    const [tabValue, setTabValue] = useState(0)
    const handleTabChange = (_, newValue) => {
        setTabValue(newValue)
    }
    const { files, links, transcriptUrl } = useSelector(
        (state: RootState) => state.playbook
    )
    const isInfoVisible = !!(files?.length || links?.length)

    return (
        <Box p={2} className={classes.sidePanelContainer}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                TabIndicatorProps={{
                    style: {
                        display: isInfoVisible ? 'block' : 'none',
                    },
                }}
            >
                <Tab
                    label="Transcript"
                    className={classes.tab}
                    disabled={!isInfoVisible}
                />
                {isInfoVisible && <Tab label="Info" className={classes.tab} />}
            </Tabs>
            <TabPanel
                value={tabValue}
                index={0}
                p={0}
                id="tabPanel1"
                className={classes.tabPanel}
            >
                <Box id="transcriptBox">
                    <Transcript
                        playbook={playbook}
                        transcriptUrl={transcriptUrl}
                        transcript={transcript}
                        startTime={startTime}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <InfoTab
                    files={files}
                    links={links}
                    isInfoVisible={isInfoVisible}
                />
            </TabPanel>
        </Box>
    )
})
