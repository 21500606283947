import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core'

import { useBoolean, useNotification } from 'hooks'

import { generatePlaybook } from 'UI/Pages/playbooks/PlaybookHits'
import { ShareDialog } from 'UI/Components'
import { VideoPanel } from './VideoPanel'
import { SidePanel } from './SidePanel'

import { getPlaybookList, getTranscript } from 'background/services'
import {
    resetPlaybook,
    resetPlaylist,
    setActivePlaybook,
    setPlaybookList,
} from 'ducks/actions'
import { RootState } from 'ducks/rootReducer'

import { sendMessageToParentScript } from 'services/parentService'

import CompanyLogo from 'assets/icons/g-icon.png'
import { AppContext } from 'app/AppProvider'

const useStyles = makeStyles({
    wrapper: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '5px solid #cb0000',
        position: 'relative',
    },
    videoContainer: {
        height: '100%',
        position: 'relative',
        overflowX: 'hidden',
    },
    transcript: {
        overflow: 'overlay',
    },
    iconWrapper: {
        display: 'flex',
        position: 'absolute',
        zIndex: 2,
        left: 0,
        top: 0,
        width: '17px',
        height: '17px',
        backgroundColor: '#cb0000',
    },
    icon: {
        width: '100%',
        height: '100%',
    },
})

export const PagePlaylist = () => {
    const classes = useStyles()

    const { playlistMode } = useContext(AppContext)
    const { showErrorNotification } = useNotification()

    const playerContainer = useRef<HTMLDivElement | null>(null)

    const { playbook, sdkConfig } =
        useSelector((state: RootState) => state) || {}

    const { icon, color, embed } = sdkConfig

    const { playbookList, active } = useSelector(
        (state: RootState) => state.playlist
    )

    const hoverState = useBoolean()
    const showSidePanel = useBoolean()

    const [transcript, setTranscript] = useState([])

    const dispatch = useDispatch()

    const showShareDialog = useBoolean()
    const toggleShareDialog = () => showShareDialog.toggle()

    useEffect(() => {
        sendMessageToParentScript({
            action: 'RESIZE_TO_PLAYER',
            params: { showSidePanel: showSidePanel.isTrue, isSDKEmbed: embed },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSidePanel.isTrue])

    // get the transcript
    useEffect(() => {
        getTranscript(
            payload => {
                if (payload) {
                    setTranscript(payload?.segments)
                }
            },
            {
                transcriptUrl: SDK_MODE
                    ? playbookList?.[active]?.transcriptUrl
                    : playbookList?.[active]?.id,
            }
        )
    }, [dispatch, playbookList, active])

    useEffect(() => {
        if (playbook?.id) {
            getPlaybookList(
                payload => {
                    if (EXT_MODE) {
                        dispatch(setPlaybookList(payload?.videos || []))
                    }

                    if (SDK_MODE) {
                        const generatedList = payload.playbookList?.map(
                            playbook => generatePlaybook(playbook)
                        )

                        dispatch(setPlaybookList(generatedList || []))
                    }
                },
                { playbookId: playbook.id }
            )
        }
    }, [dispatch, playbook.id])

    useEffect(() => {
        return () => {
            dispatch(setActivePlaybook(0))
            dispatch(resetPlaybook())
            dispatch(resetPlaylist())
        }
    }, [dispatch])

    const isPlaylistInvalid =
        !playbook ||
        Object.values(playbook).length === 0 ||
        playbookList?.length === 0

    useEffect(() => {
        if (playbookList && isPlaylistInvalid) {
            playlistMode.setFalse()
            showErrorNotification(
                'Playlist is not available. Please try again later'
            )

            sendMessageToParentScript({
                action: 'RESIZE_TO_NORMAL',
                params: { isSDKEmbed: embed },
            })
        }
        // eslint-disable-next-line
    }, [playbookList, isPlaylistInvalid])

    if (!playbookList) {
        return <LinearProgress />
    }

    if (isPlaylistInvalid) {
        return null
    }

    return (
        <Box
            className={classes.wrapper}
            style={{
                width: 'auto',
                borderColor: color || '#cb0000',
            }}
        >
            <Box
                className={classes.iconWrapper}
                style={{
                    backgroundColor: color || '#cb0000',
                }}
            >
                <img
                    src={icon || CompanyLogo}
                    alt="guidde_logo"
                    className={classes.icon}
                />
            </Box>

            <Grid
                container
                onMouseEnter={hoverState.setTrue}
                onMouseLeave={hoverState.setFalse}
            >
                <Grid
                    item
                    xs={showSidePanel.isTrue ? 8 : 12}
                    md={showSidePanel.isTrue ? 8 : 12}
                    className={classes.videoContainer}
                    ref={playerContainer}
                >
                    <VideoPanel
                        hoverState={hoverState}
                        showSidePanel={showSidePanel}
                        showShareDialog={showShareDialog}
                        playbook={playbookList?.[active]}
                    />
                </Grid>

                {showSidePanel.isTrue && (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.transcript}
                        style={{
                            maxHeight:
                                playerContainer?.current?.offsetHeight || 480,
                        }}
                    >
                        <SidePanel
                            transcript={transcript}
                            playbook={playbookList?.[active]}
                        />
                    </Grid>
                )}
            </Grid>
            {showShareDialog.isTrue && (
                <ShareDialog onClose={toggleShareDialog} playbook={playbook} />
            )}
        </Box>
    )
}
