import React from 'react'
import { Typography } from '@material-ui/core'

import { InfoList } from './InfoList'

export type InfoItem = {
    id: string
    uid: string
    name: string
    url: string
}

type Props = {
    files?: Array<InfoItem>
    links?: Array<InfoItem>
    isInfoVisible: boolean
}

export const InfoTab = ({ files, links, isInfoVisible }: Props) => {
    return (
        <>
            {isInfoVisible ? (
                <InfoList files={files} links={links} />
            ) : (
                <Typography gutterBottom align="center">
                    Enrich your video with additional resources
                </Typography>
            )}
        </>
    )
}
