import React, { useEffect, useMemo, useState } from 'react'
import {
    Dialog,
    makeStyles,
    Box,
    IconButton,
    DialogContent,
    Select,
    Input,
    Typography,
    FormControl,
} from '@material-ui/core'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import MenuItem from '@material-ui/core/MenuItem'
import LinearProgress from '@material-ui/core/LinearProgress'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { ReactComponent as MagicWand } from 'assets/icons/magic-wand.svg'

import { getMagicCaptureConfig } from 'background/services'
import { LanguageType, MagicCaptureType, TopicType } from 'types'
import {
    MAGIC_CAPTURE_LAST_LANG,
    MAGIC_CAPTURE_LAST_TYPE,
} from 'background/constants'
import { useStorageSetting } from 'hooks'

const useStyles = makeStyles(({ spacing }) => ({
    dialog: { width: '380px', backgroundColor: 'transparent' },
    dialogTitle: {
        display: 'flex',
        backgroundColor: '#090C10',
        color: '#FFFFFF',
    },
    dialogContent: {
        padding: spacing(2, 4, 0),
        backgroundColor: '#ffffff',
    },
    dialogActions: {
        backgroundColor: '#ffffff',
        padding: spacing(2, 4),
    },
    wandIcon: {
        width: '100%',
        height: '100%',
    },
    ctIcon: { width: '16px', marginRight: spacing(1) },
    iconWrapper: {
        marginRight: spacing(1),
        width: '30px',
        height: '30px',
        flexShrink: 0,
        backgroundColor: '#ffffff',
        borderRadius: '50%',
    },
    title: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#FFFFFF',
    },
    contentTitle: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#090C10',
        marginBottom: spacing(1),
    },
    contentText: {
        fontWeight: 400,
        fontSize: '12px',
        color: 'rgba(9, 12, 16, 0.6)',
        marginBottom: spacing(1),
    },
    titleText: {
        fontWeight: 400,
        fontSize: '12px',
        color: '#C6C6C7',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: spacing(0.5),
        margin: spacing(1),
        color: '#75767C',
    },
    select: {
        width: '100%',
        padding: spacing(2, 2),
        backgroundColor: '#F5F5F5',
        borderRadius: '4px 4px 0px 0px',
    },
    input: {
        backgroundColor: '#F5F5F5',
        padding: spacing(1, 2),
        fontSize: '14px',
    },
    placeholder: {
        '&::placeholder': {
            fontStyle: 'italic',
            color: 'rgb(9, 12, 16)',
        },
    },
    langSelect: {
        fontWeight: 400,
        fontSize: '14px',
        color: 'rgba(9, 12, 16, 0.6)',
    },
    selectMenu: { height: '400px' },
    ctaBtn: {
        marginLeft: spacing(1),
        letterSpacing: '1.25px',
    },
}))

type Props = {
    showMagicCaptureLanguages: boolean
    startQG: (mc: MagicCaptureType) => void
    onClose: () => void
}

export const MagicCaptureDialog = ({
    showMagicCaptureLanguages,
    startQG,
    onClose,
}: Props) => {
    const classes = useStyles()
    const [captureType, setCaptureType] = useState<string>('')
    const [language, setLanguage] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [magicCaptureOptions, setMagicCaptureOptions] = useState<{
        captureTypes: TopicType[]
        languages: LanguageType[]
    } | null>(null)

    const {
        storageSetting: preferredLang,
        changeSetting: changePreferredLang,
    } = useStorageSetting(MAGIC_CAPTURE_LAST_LANG)
    const {
        storageSetting: preferredType,
        changeSetting: changePreferredType,
    } = useStorageSetting(MAGIC_CAPTURE_LAST_TYPE)

    const handleSubmit = () => {
        const ct = magicCaptureOptions?.captureTypes.find(
            mc => mc.id === captureType
        )
        const lang = magicCaptureOptions?.languages.find(
            l => l.langCode === language
        )

        changePreferredType(captureType)
        changePreferredLang(language)
        startQG({
            description,
            captureType: ct,
            language: lang,
        })
    }

    useEffect(() => {
        getMagicCaptureConfig(response => {
            setMagicCaptureOptions(response)
        })
    }, [])

    const disabled = useMemo(() => {
        return !description || description.length > 150 || !captureType
    }, [description, captureType])

    useEffect(() => {
        if (!magicCaptureOptions || language || captureType) return

        // Set defaults
        const { captureTypes, languages } = magicCaptureOptions

        const prefLang = languages.find(l => l.langCode === preferredLang)
        const prefType = captureTypes.find(ct => ct.id === preferredType)

        const defaultLang = languages.find(l => l.langCode === 'en-US')

        setCaptureType(prefType?.id || captureTypes[0]?.id || '')
        setLanguage(prefLang?.langCode || defaultLang?.langCode || '')
    }, [
        captureType,
        language,
        magicCaptureOptions,
        preferredLang,
        preferredType,
    ])

    const sortedLanguages: LanguageType[] = useMemo(() => {
        return (
            magicCaptureOptions?.languages?.sort((a, b) => {
                // Sort alphabetically based on language name
                if (a.langName < b.langName) return -1
                if (a.langName > b.langName) return 1
                return 0
            }) || []
        )
    }, [magicCaptureOptions?.languages])

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth="xs"
            onClose={onClose}
            classes={{ paper: classes.dialog }}
        >
            {!magicCaptureOptions && <LinearProgress />}
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <div className={classes.iconWrapper}>
                    <MagicWand className={classes.wandIcon} />
                </div>
                <Box>
                    <Typography className={classes.title}>
                        Magic Capture
                    </Typography>
                    <Typography className={classes.titleText}>
                        Let Guidde help you generate how-to videos & documents
                        in seconds
                    </Typography>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon color="inherit" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Box alignItems="flex-start" flexDirection="column">
                    <Typography className={classes.contentTitle}>
                        What are you capturing?
                    </Typography>
                    <FormControl variant="filled" fullWidth>
                        <Select
                            value={captureType}
                            onChange={event => {
                                setCaptureType(event.target.value as string)
                            }}
                            classes={{ root: classes.select }}
                        >
                            {magicCaptureOptions?.captureTypes.map(
                                (ct: TopicType) => (
                                    <MenuItem key={ct.id} value={ct.id}>
                                        <Box display="flex" alignItems="center">
                                            <img
                                                alt=""
                                                src={ct.imageUrl}
                                                className={classes.ctIcon}
                                            />
                                            {ct.title}
                                        </Box>
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>

                    <Box mt={2.5} mb={1}>
                        <Typography className={classes.contentTitle}>
                            Describe your how-to
                        </Typography>
                        <Typography className={classes.contentText}>
                            The better you can describe what you want to
                            capture, the higher quality content Guidde will help
                            produce
                        </Typography>

                        <Input
                            autoFocus
                            required
                            fullWidth
                            placeholder="Example: How to invite a new user"
                            minRows={2}
                            maxRows={5}
                            classes={{
                                root: classes.input,
                                input: classes.placeholder,
                            }}
                            error={!description || description.length > 150}
                            value={description}
                            onChange={event => {
                                if (event.target.value.length <= 150) {
                                    setDescription(event.target.value as string)
                                }
                            }}
                            onKeyUp={e => {
                                if (e.key === 'Enter') {
                                    if (disabled) return
                                    handleSubmit()
                                }
                            }}
                        />
                        <Box display="flex" justifyContent="flex-end">
                            <Typography className={classes.contentText}>
                                {description.length}/150
                            </Typography>
                        </Box>
                    </Box>
                    {showMagicCaptureLanguages && (
                        <>
                            <Typography className={classes.contentTitle}>
                                Select a language
                            </Typography>
                            <Select
                                disableUnderline
                                classes={{ root: classes.langSelect }}
                                value={language}
                                onChange={event => {
                                    setLanguage(event.target.value as string)
                                }}
                                MenuProps={{
                                    classes: { paper: classes.selectMenu },
                                }}
                            >
                                {sortedLanguages.map((lt: LanguageType) => (
                                    <MenuItem
                                        key={lt.langCode}
                                        value={lt.langCode}
                                    >
                                        {lt.langName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    disabled={disabled}
                    color="primary"
                    onClick={handleSubmit}
                >
                    <ArrowForwardIcon />
                    <span className={classes.ctaBtn}>GO</span>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
