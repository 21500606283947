import firebase from './init'
import { registerAnalyticsSuperProps } from '../background/analytics'
import { subscribeForNotifications } from './notifications'
import { isEmpty, isEqual } from 'lodash'
import { ApiError, getHost } from '../api/utils'
import { captureSentryError, captureSentryEvent } from '../services/sentry'
import { firebaseAppsGC } from './apps'
import { clearTagsParsingAPI, subscribeToTagsParsingAPI } from './tagParser'
import { firebaseOrgGC } from './organization'
import { firebaseFeatureFlagsGC } from './featureFlags'
import { firebaseUserAchievementsGC } from './userAchievements'
import { firebaseUserSpacesGC } from './userSpaces'
import { firebaseOrgUsersGC } from './orgUsers'
import { servicesUsageGC } from './servicesUsage'
import { firebaseUserQuickActionsGC } from './quickActionUserSettings'
import { firebaseFeatureFlagsGlobalGC } from './featureFlagsGlobal'

import { User, UserCallback } from '../types'

let firebaseUser: User = null

export const getUser = () => firebaseUser

const getIdToken = (forceRefresh?: boolean) =>
    firebase.auth().currentUser?.getIdToken(forceRefresh)

export const getToken = () => {
    return (
        getIdToken()?.then(res => {
            if (!res) return getIdToken(true)

            return res
        }) || Promise.resolve()
    )
}

const setUser = (user: User, callback: UserCallback) => {
    firebaseUser = user
    callback(user)
}

export const registerToFirebaseAuth = (callback: UserCallback) => {
    let notifObserver: any = null

    firebase.auth().onAuthStateChanged(user => {
        if (!user) {
            setUser(null, callback)
            registerAnalyticsSuperProps()
            clearTagsParsingAPI()
            notifObserver && notifObserver()
        }
    })

    firebase.auth().onIdTokenChanged(user => {
        if (!user) {
            // onAuthStateChanged handles it
            return null
        }

        user.getIdTokenResult().then(idTokenResult => {
            const extUser: User = {
                user: user,
                roles: idTokenResult.claims.roles,
            }

            // not ready yet
            if (!idTokenResult.claims.roles) return null

            setUser(extUser, callback)
            registerAnalyticsSuperProps(extUser)

            subscribeToTagsParsingAPI()
            notifObserver = subscribeForNotifications(user.uid)
        })
    })
}

type AuthResponseType =
    | {
          tenant: string
          token: string
      }
    | {
          error: string
      }

export const registerSDKToFirebaseAuth = (
    tenant: string,
    apiKey: string,
    session: string,
    userInfo: any,
    callback: UserCallback
) => {
    firebase.auth().tenantId = tenant

    if (!userInfo || isEmpty(userInfo)) userInfo = undefined

    let isSdkInitialized = false

    firebase.auth().onAuthStateChanged(user => {
        if (!user && !isSdkInitialized) {
            const authUrl = `${getHost()}/i/v1/auth`
            const tenant = getTenant()
            const body = JSON.stringify({
                apiKey,
                session,
                user: { userInfo },
            })

            if (!tenant) {
                new ApiError(
                    400,
                    authUrl,
                    'No tenant: ' + JSON.stringify(body, null, 2)
                )
            }

            return fetch(authUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Guidde-Tenant': tenant || '',
                },
                body,
            })
                .then(async res => {
                    const data = (await res.json()) as AuthResponseType

                    if ('token' in data) {
                        isSdkInitialized = true
                        loginFirebase(data?.token)
                    } else {
                        throw new Error(
                            `Response doesn't have token property ${data.error}`
                        )
                    }
                })
                .catch(e => {
                    captureSentryError(e)
                    throw new Error(e)
                })
        }
    })

    firebase.auth().onIdTokenChanged(user => {
        if (!user) {
            // onAuthStateChanged handles it
            return null
        }

        user.getIdTokenResult().then(idTokenResult => {
            const sdkUser: User = {
                user: user,
                roles: null,
                userInfo: idTokenResult.claims.userInfo,
            }
            if (!isEqual(userInfo, sdkUser.userInfo)) {
                return firebase
                    .auth()
                    .signOut()
                    .catch(_ => {})
            }

            setUser(sdkUser, callback)
        })
    })
}

export const logoutFirebase = () =>
    new Promise(resolve =>
        firebase
            .auth()
            .signOut()
            .then(() => {
                // Run Garbage Collectors on logout
                firebaseAppsGC()
                firebaseOrgGC()
                firebaseFeatureFlagsGC()
                firebaseFeatureFlagsGlobalGC()
                firebaseUserAchievementsGC()
                firebaseUserSpacesGC()
                firebaseOrgUsersGC()
                servicesUsageGC()
                firebaseUserQuickActionsGC()

                resolve(true)
            })
            .catch(error => {
                captureSentryEvent('Firebase logout', true, { error })
                resolve(false)
            })
    )

export const getTenant = () => firebase.auth().tenantId

export const loginFirebase = (customToken, successCallback = () => {}) =>
    customToken
        ? new Promise(resolve => {
              firebase
                  .auth()
                  .signInWithCustomToken(customToken)
                  .then(res => {
                      resolve(res.user)
                      successCallback()
                  })
                  .catch(error => {
                      captureSentryError(error, { meta: 'Login error' })
                      resolve(null)
                  })
          })
        : logoutFirebase()
