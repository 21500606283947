import { ReactNode, CSSProperties } from 'react'
import { makeStyles, BoxProps, Box } from '@material-ui/core'

type StyleProps = {
    spacing: number
}
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: ({ spacing }: StyleProps) => theme.spacing(spacing),
        },
    },
}))

type Props = {
    children: ReactNode
    className?: string
    style?: CSSProperties
    spacing?: number
    alignItems?: BoxProps['alignItems']
} & BoxProps

export const SpacedGroup = ({
    children,
    className,
    style,
    spacing = 2,
    alignItems = 'center',
    ...rest
}: Props) => {
    const classes = useStyles({ spacing })

    return (
        <Box
            alignItems={alignItems}
            className={`${classes.root} ${className}`}
            style={style}
            {...rest}
        >
            {children}
        </Box>
    )
}
