import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    ListItemText,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Tag } from 'UI/Components/Tag'
import { AddTag } from 'UI/Components/AddTag'

import {
    getPbRequestDefaults,
    logToAnalytics,
    requestPlaybook,
} from 'background/services'
import { useBoolean, useParentLocation } from 'hooks'
import { RootState } from 'ducks/rootReducer'
import {
    clearRequestPbModal,
    openRequestPbModal,
    overWriteRequestPbFields,
} from 'ducks/actions'
import { option } from 'services/utils'

import { Recording, SpaceType } from 'types'

import {
    getDialogSharedStyles,
    REQUEST_TYPE_KEY,
    REQUEST_TYPES,
} from './shared'

type RequestPayload = {
    subject: null | string
    requestType: string
    url: string
    applicationId: string
    tags: Array<string>
    recordings?: Array<Recording>
    spaces: Array<string>
    isMainSelected: boolean
}

const useStyles = makeStyles(theme => ({
    ...getDialogSharedStyles(theme),
    tags: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    tagLabel: {
        fontSize: '12px',
        color: '#7f8c9a',
    },
    fieldLabel: {
        marginBottom: 0,
        color: '#7f8c9a',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
    },
    fieldWithError: {
        color: '#f44336',
    },
    errorMessage: {
        color: 'inherit',
    },
    inputRoot: {
        '&:after': {
            borderColor: theme.palette.secondary.main,
        },
    },
    inputRootWithError: {
        '&:after': {
            transform: 'scaleX(1)',
            borderColor: '#f44336',
        },
    },
    spaceLogoBox: {
        width: '32px',
        height: '32px',
        paddingRight: theme.spacing(1),

        '& img': {
            width: 'inherit',
            height: 'inherit',
            objectFit: 'contain',
        },
    },
    spaceOption: {
        '&[aria-selected="true"]:not([data-focus="true"])': {
            background: 'transparent',
        },
    },
}))

type PbRequestDefaults = {
    orgName: string
    orgLogo: string
    defaultSpaceId?: string
}

type RequestPbState = {
    subject: string
    applicationId: string
    requestType: string
    tags: Array<string>
    recordings: Array<Recording>
    toSpaces: Array<string>
}

const MAIN_SPACE_ID = 'MAIN_SPACE_ID'

export const RequestPlaybookDialog = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const loading = useBoolean()
    const location = useParentLocation()
    const openDialogRef = useRef<typeof openRequestPbModal | null>(null)
    const [pbRequestDefaults, setPbRequestDefaults] =
        useState<PbRequestDefaults>()

    const requestPbState: RequestPbState = useSelector(
        (state: RootState) => state.requestPb
    )
    const apps = useSelector((state: RootState) => state.apps)
    const sortedApps = useMemo(
        () =>
            apps
                .map(app => option(app.id, app.title))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [apps]
    )
    const { subject, applicationId, requestType, tags, recordings, toSpaces } =
        requestPbState

    const onClose = () => {
        openDialogRef.current = null
        dispatch(clearRequestPbModal())
    }

    const changeField = (field: string, value: any) => {
        dispatch(overWriteRequestPbFields({ [field]: value }))
    }

    const onSubmit = () => {
        const spaces = toSpaces.filter(spaceId => spaceId !== MAIN_SPACE_ID)
        const payload: RequestPayload = {
            subject: subject.trim(),
            requestType,
            applicationId,
            tags,
            spaces,
            isMainSelected: toSpaces.includes(MAIN_SPACE_ID),
            url: location?.href || '',
            ...(recordings.length ? { recordings } : {}),
        }

        loading.setTrue()

        requestPlaybook(() => {
            loading.setFalse()
            onClose()
            logToAnalytics('request_playbook_call_made', payload)
        }, payload)
    }

    const isSubjInvalid = !subject.trim()
    const isToInvalid = !toSpaces.length

    useEffect(() => {
        // initial select of default spaces for user
        if (pbRequestDefaults) {
            return
        }

        getPbRequestDefaults(defaults => {
            setPbRequestDefaults(defaults)

            if (toSpaces.length) {
                return
            }

            dispatch(
                overWriteRequestPbFields({
                    toSpaces: [defaults?.defaultSpaceId || MAIN_SPACE_ID],
                })
            )
        })
    }, [pbRequestDefaults, dispatch, toSpaces.length])

    const availableTags: Array<{
        value: string
        label: string
    }> =
        useSelector((state: RootState) =>
            Object.entries(state.tags || {}).map(([tag, tagOccurrences]) =>
                option(tag, `${tag} (${tagOccurrences})`)
            )
        ) || []

    const mainSpace = {
        id: MAIN_SPACE_ID,
        name: pbRequestDefaults?.orgName || '',
        logo: pbRequestDefaults?.orgLogo || '',
    }
    const spacesObj = useSelector((state: RootState) => state.spaces || {})
    const spaces = Object.values<SpaceType>(spacesObj)
    const allSpaces = [mainSpace, ...spaces]

    return (
        <Dialog
            open
            fullWidth
            onClose={() => {
                onClose()
            }}
            maxWidth="md"
        >
            {loading.isTrue && <LinearProgress />}
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                    onClose()
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogTitle classes={{ root: classes.titleWrapper }}>
                <Typography className={classes.title}>Ask a Guidde</Typography>
                <Divider />
            </DialogTitle>
            <DialogContent className={classes.content}>
                <TextField
                    fullWidth
                    color="secondary"
                    value={subject}
                    error={isSubjInvalid}
                    onChange={e => changeField('subject', e.target.value)}
                    label={
                        requestType === REQUEST_TYPE_KEY.QUESTION
                            ? 'Enter your question'
                            : 'Enter your note'
                    }
                    InputLabelProps={{
                        shrink: true,
                        required: true,
                        classes: { root: classes.fieldLabel },
                    }}
                />
                <Typography
                    className={clsx(
                        classes.fieldLabel,
                        isToInvalid ? classes.fieldWithError : ''
                    )}
                >
                    To*
                    {isToInvalid && (
                        <Typography className={classes.errorMessage}>
                            Field is required
                        </Typography>
                    )}
                </Typography>

                <Autocomplete
                    options={allSpaces}
                    fullWidth
                    value={allSpaces.filter(({ id }) => toSpaces.includes(id))}
                    multiple
                    classes={{
                        inputRoot: clsx(
                            classes.inputRoot,
                            isToInvalid ? classes.inputRootWithError : ''
                        ),
                        option: classes.spaceOption,
                    }}
                    disableCloseOnSelect
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) =>
                        value.id === option.id
                    }
                    renderInput={params => <TextField {...params} />}
                    onChange={(_e, value) =>
                        dispatch(
                            overWriteRequestPbFields({
                                toSpaces: value.map(({ id }) => id),
                            })
                        )
                    }
                    renderOption={({ id, name, logo }) => (
                        <React.Fragment key={id}>
                            <Checkbox
                                color="primary"
                                checked={toSpaces.includes(id)}
                            />
                            <Box className={classes.spaceLogoBox}>
                                <img src={logo} alt="" />
                            </Box>
                            <ListItemText primary={name} />
                        </React.Fragment>
                    )}
                />

                <TextField
                    select
                    fullWidth
                    value={requestType}
                    color="secondary"
                    onChange={e => changeField('requestType', e.target.value)}
                    label="Request Type"
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.fieldLabel },
                    }}
                >
                    {REQUEST_TYPES.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                    <TextField
                        select
                        color="secondary"
                        value={applicationId}
                        onChange={e =>
                            changeField('applicationId', e.target.value)
                        }
                        label="Application name"
                        InputLabelProps={{
                            classes: { root: classes.fieldLabel },
                        }}
                        SelectProps={{
                            autoWidth: true,
                            style: { minWidth: '200px' },
                        }}
                        style={{ flexShrink: 0 }}
                    >
                        {sortedApps.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box ml={2} display="flex" flexDirection="column">
                        <Typography className={classes.tagLabel}>
                            Tags
                        </Typography>
                        <div className={classes.tags}>
                            {tags?.map(tag => (
                                <Tag
                                    key={tag}
                                    tag={tag}
                                    onDelete={() => {
                                        changeField(
                                            'tags',
                                            tags.filter(
                                                appliedTag => appliedTag !== tag
                                            )
                                        )
                                    }}
                                />
                            ))}

                            <AddTag
                                autocompleteOptions={availableTags}
                                onApply={tag => {
                                    const trimmedTag = tag.replaceAll(' ', '')

                                    if (!tags.includes(trimmedTag)) {
                                        changeField(
                                            'tags',
                                            tags.concat(trimmedTag)
                                        )
                                    }
                                }}
                            />
                        </div>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    size="medium"
                    type="submit"
                    disabled={loading.isTrue || isSubjInvalid || isToInvalid}
                    onClick={onSubmit}
                >
                    Send request
                </Button>
            </DialogActions>
        </Dialog>
    )
}
