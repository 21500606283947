const initialRequestPbState = {
    isModalOpen: false,
    subject: '',
    applicationId: '',
    requestType: 'question',
    toSpaces: [],
    tags: [],
    recordings: [],
}

export const requestPbReducer = (state = initialRequestPbState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'REQUEST_PB/openModal':
            return {
                ...state,
                isModalOpen: true,
                ...payload.predefinedFields,
            }
        case 'REQUEST_PB/hideModal':
            return { ...state, isModalOpen: false }
        case 'REQUEST_PB/clearModal':
            return initialRequestPbState
        case 'REQUEST_PB/overwriteFields':
            return { ...state, ...payload.fields }
        default:
            return state
    }
}
