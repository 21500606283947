/* This is a firebase init script.
   Firebase libraries should be imported only here and later re-used across the project.

   IMPORTANT!
   Firebase was updated from v8 to v9 in compatibility (compat) mode.
   Compat mode will be removed in the future major version of firebase.
   Use new (modular) approach to create new functionality.

   TODO: Migrate from v9 compat mode to v9 modular syntax.
   Read: https://firebase.google.com/docs/web/modular-upgrade
*/

import firebase from 'firebase/compat/app'

/* Use only the modules you actually need in the project. */
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'

// IMPORTANT! Do not use 'firebase/storage' module.
// File upload uses XHR and you won't be able to use it in the background (service worker restrictions).
// Read: https://github.com/firebase/firebase-js-sdk/issues/6595

import Config from '../config'
firebase.initializeApp(Config.firebase)

export default firebase
