import Config from '../config'
import { CopyGifProps } from '../types/copyGif'

export const getLink = (
    { isPublic, isPlaylist, id },
    {
        trackLinkName,
        trackLinkId,
    }: { trackLinkName?: string; trackLinkId?: string },
    uid
) => {
    const host = `https://${Config.firebase.authDomain}`

    const playbookPath = !isPublic
        ? `/playbooks/${id}`
        : `/share/playbooks/${id}`

    const playlistPath = !isPublic
        ? `/playbooks/playlist/${id}`
        : `/share/playlists/${id}`

    const path = isPlaylist ? playlistPath : playbookPath

    const urlParams = new URLSearchParams()

    if (trackLinkName) {
        urlParams.append('track_link_name', trackLinkName)
    }

    if (trackLinkId) {
        urlParams.append('track_link_id', trackLinkId)
    }

    urlParams.append('origin', uid)

    return `${host}${path}?${urlParams.toString()}`
}

export const getTitle = playbook =>
    playbook.title || playbook.originalFileName?.replace(/\.mp4$/i, '')

export const copyGif = async ({
    playbook,
    uid,
    trackLinkName,
    trackLinkId,
}: CopyGifProps) => {
    const { isPublic, id, isPlaylist, gifUrl, screenshotUrl } = playbook

    return new Promise<Node>(resolve => {
        const playbookThumbnail = gifUrl || screenshotUrl
        const playbookTitle = getTitle(playbook)

        const link = getLink(
            { isPublic, isPlaylist, id },
            { trackLinkName, trackLinkId },
            uid
        )

        const isQuickGuidde = playbook.mode === 'quickGuidde'
        const width = isQuickGuidde ? '700px' : '250px'
        const height = isQuickGuidde ? 'auto' : '175px'

        const imageHtml = `<a href=${link}>
              <p>${playbookTitle}</p>
              <img style="width:${width};height:${height};object-fit:contain;border-radius:2px" src=${playbookThumbnail} alt=""/>
           </a>`

        // generate HTML
        const container = document.createElement('div')
        container.innerHTML = imageHtml
        container.style.position = 'fixed'
        container.style.pointerEvents = 'none'
        container.style.opacity = '0'
        document.body.appendChild(container)
        window?.getSelection()?.removeAllRanges()
        const range = document.createRange()
        range.selectNode(container)
        window?.getSelection()?.addRange(range)
        document.execCommand('copy')
        document.body.removeChild(container)

        // After removing from body, reset styles
        container.removeAttribute('style')

        resolve(container)
    })
}
