import React from 'react'
import ReactDOM, { Renderer } from 'react-dom'
import { Provider } from 'react-redux'

import {
    createGenerateClassName,
    StylesProvider,
    MuiThemeProvider,
} from '@material-ui/core'
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline'

import { checkIsExtension } from 'services/environmentService'

import { AppLibrary } from 'AppLibrary'
import { AppRecording } from 'AppRecording'
import './index.css'

import theme from 'app/theme'
import { AppProvider } from 'app/AppProvider'
import { NotificationProvider, NotificationsProvider } from 'UI/Components'
import { store } from 'ducks/store'
import { initSentry } from './services/sentry'

type RootElement = Parameters<Renderer>['0'][0]
type ContainerSelector = string

// Sentry entrypoint for all React iframes
initSentry()

interface AppSetupConfig {
    rootElement: RootElement
    injectExtensionTo: ContainerSelector
    injectWebAppTo: ContainerSelector
}

const findElementInDOM = (selector: ContainerSelector) =>
    document.querySelector(selector)

const renderAppToDOM = (element: RootElement, selector: ContainerSelector) => {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(element, document.querySelector(selector))
}

const injectExtensionToDOM = (
    element: RootElement,
    selector: ContainerSelector
) => {
    const rootElementId = 'guidde-extension'

    const prevContainer = document.getElementById(rootElementId)
    prevContainer?.remove()

    const appContainer = document.createElement('div')
    appContainer.id = rootElementId
    appContainer.className = rootElementId

    const elementInDOM = findElementInDOM(selector)

    if (elementInDOM) {
        elementInDOM.append(appContainer)
        renderAppToDOM(element, `#${rootElementId}`)
    }
}

const initExtension = (element: RootElement, selector: ContainerSelector) => {
    const interval = setInterval(() => {
        // Can't inject the extension to DOM.
        if (!findElementInDOM(selector)) {
            return
        }

        clearInterval(interval)
        injectExtensionToDOM(element, selector)
    }, 100)
} // check every 100ms

const setupProject = (config: AppSetupConfig) => {
    if (checkIsExtension()) {
        initExtension(config.rootElement, config.injectExtensionTo)
    } else {
        renderAppToDOM(config.rootElement, config.injectWebAppTo)
    }
}

const initReact = () => {
    // In order to prevent style conflicts
    const generateClassName = EXT_MODE
        ? createGenerateClassName({
              productionPrefix: 'guidde',
              disableGlobal: true,
              seed: 'ext',
          })
        : undefined

    // Renders the web-app/extension content to DOM.
    setupProject({
        rootElement: (
            <React.StrictMode>
                <MuiThemeProvider theme={theme}>
                    <StylesProvider generateClassName={generateClassName}>
                        <ScopedCssBaseline
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <Provider store={store}>
                                <AppProvider>
                                    <NotificationProvider>
                                        <NotificationsProvider>
                                            {LIB_MODE && <AppLibrary />}
                                            {REC_MODE && <AppRecording />}
                                        </NotificationsProvider>
                                    </NotificationProvider>
                                </AppProvider>
                            </Provider>
                        </ScopedCssBaseline>
                    </StylesProvider>
                </MuiThemeProvider>
            </React.StrictMode>
        ),
        injectExtensionTo: 'body',
        injectWebAppTo: '#root',
    })
}

export { initReact }
