import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import {
    NoStepsMessageDialog,
    QuickGuiddeStart,
    VideoRecorder,
} from './UI/Components'
import {
    getFirebaseUser,
    getQGRecordingState,
    subscribeToRecordingStatus,
    getUserAchievements,
    logToAnalytics,
    onQGScreenshotRemote,
    onRecordFromBackground,
    onRecordFromContext,
    onRecordFromKeyboard,
    onRecordFromWebapp,
    onRestartPB,
    onRestartQG,
    sendManualQGStep,
    startQGRecording,
    startScreenCapture,
    stopQGRecordingNow,
    stopScreenCapture,
    onPauseResumeRemote,
    pauseResumeQGRecording,
    onQGStatusChange,
} from './background/services'
import { setRecordingStatus } from './ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { trimResultTag } from './services/tagsParser'
import { getSelectionText } from './services/utils'
import { RootState } from './ducks/rootReducer'
import { AppContext } from './app/AppProvider'
import { Dialog } from '@material-ui/core'
import {
    useBoolean,
    useOrgFeatureFlags,
    useParentListener,
    useParentLocation,
    useStorageSetting,
    useTagsParser,
} from './hooks'
import { initialUserState } from './ducks/reducers'
import { setSentryUser } from './services/sentry'
import {
    composeGlobalProps,
    composeUserProps,
} from './services/utilsForAnalytics'
import { QG_STATE, QG_STATE_UNION } from './types/quickGuidde'
import { getDimensions } from './services/qgService'
import { RecordingIframeActionE } from './services/parentService'
import { MagicCaptureType, RecordingStatusE } from './types'
import { AlmostThereDialog } from 'UI/Components/AlmostThereDialog'
import Config from './config'
import { WEBCAM_SETTING } from './background/constants'
import { PinExtDialog } from './UI/Components/PinExtDialog'
import { RecPanel } from './UI/Components/RecPanel'
import { ProgressState } from './UI/Components/ProgressState'

const useStyles = makeStyles({
    main: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: '1vh 3vw',
    },
    container: {
        height: 'auto',
    },
    modal: {
        width: '320px',
        margin: '0',
        fontFamily: 'Roboto, sans-serif',
    },
    qgOnlyModal: {
        backgroundColor: '#ffffff',
    },
    regularModal: {
        backgroundColor: '#F5F5F5',
    },
})

type MixpanelRecordingSource =
    | ''
    | 'start_recording'
    | 'start_recording_from_webapp'
    | 'start_recording_from_context_menu'
    | 'start_recording_from_keyboard_shortcut'
    | 'start_recording_from_browserAction'

export const AppRecording = () => {
    const { showQGRecordOnly } = useOrgFeatureFlags()
    const classes = useStyles()
    const dispatch = useDispatch()

    const { openModalMode, closeModalMode } = useContext(AppContext)
    const [mixpanelSource, setMixpanelSource] =
        useState<MixpanelRecordingSource>('')
    const [textSelection, setTextSelection] = useState<string>('')
    const { status: pbStatus } = useSelector(
        (state: RootState) => state.screenCapture
    )
    const [isFirstQG, setQGStatus] = useState<boolean | null>(null)
    const [qgState, setQGState] = useState<QG_STATE_UNION | null>(null)

    const { roles } = useSelector((state: RootState) => state.user)
    const almostThereDialog = useBoolean()
    const url = `https://${Config.firebase.authDomain}/onboarding`

    const showRecSettingsDialog = useBoolean()
    const showMessageDialog = useBoolean()
    const showQGStartPage = useBoolean()
    const showPinExtDialog = useBoolean()

    const isPbRecording = pbStatus === RecordingStatusE.RECORDING
    const isQGRecording =
        qgState === QG_STATE.RECORDING || qgState === QG_STATE.PAUSED

    const isRecording = isPbRecording || isQGRecording
    const isProcessing =
        pbStatus === RecordingStatusE.PROCESSING ||
        qgState === QG_STATE.PROCESSING

    const remoteRecControls = useCallback(
        (source: 'contextMenu' | 'keyboardShortcut', selection?: string) => {
            getQGRecordingState(qgState => {
                // If playbook is in progress, stop it
                if (isPbRecording) {
                    stopScreenCapture({ mxpMeta: { stopSource: source } })
                    return
                }

                const isQGRecording = qgState.state !== QG_STATE.READY

                // If QG is in progress, stop it
                if (isQGRecording) {
                    stopQGRecordingNow(false)
                    return
                }

                // QG and Playbook are not in progress, show rec UI
                const textSelection = selection || getSelectionText()
                const newTag = trimResultTag(textSelection)
                setTextSelection(newTag)

                setMixpanelSource(
                    source === 'contextMenu'
                        ? 'start_recording_from_context_menu'
                        : 'start_recording_from_keyboard_shortcut'
                )

                logToAnalytics('open_recording_panel', {
                    source,
                })

                openModalMode()
            })
        },
        [isPbRecording, openModalMode]
    )

    useEffect(() => {
        onRecordFromBackground(() => {
            if (!isPbRecording) {
                openModalMode()
                setMixpanelSource('start_recording_from_browserAction')
            }
        })

        onRecordFromWebapp(() => {
            if (!isPbRecording) {
                openModalMode()
                setMixpanelSource('start_recording_from_webapp')
            }
        })

        onRecordFromContext(({ selection }) =>
            remoteRecControls('contextMenu', selection)
        )

        onRecordFromKeyboard(() => remoteRecControls('keyboardShortcut'))
    }, [isPbRecording, openModalMode, remoteRecControls])

    useEffect(() => {
        // sendManualQGStep in background has a check whether a QG is in progress
        onQGScreenshotRemote(() => {
            sendManualQGStep(true)
        })

        onPauseResumeRemote(() => {
            closeModalMode()
            pauseResumeQGRecording()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Permanent listener should be established once, keep this empty!

    useEffect(() => {
        getFirebaseUser(payload => {
            dispatch({
                type: 'userChanged',
                payload: payload || initialUserState,
            })

            setSentryUser({
                ...composeGlobalProps(),
                ...composeUserProps(payload),
                id: payload?.user?.uid,
                username: payload?.user?.displayName,
                email: payload?.user?.email,
            })
        })

        subscribeToRecordingStatus(payload => {
            dispatch(setRecordingStatus(payload.status))
        })

        getUserAchievements(data => {
            setQGStatus(!data?.createdFirstQuickGuidde)
        })

        getQGRecordingState(({ state }) => {
            setQGState(state)
        })

        onQGStatusChange(({ newState }) => {
            setQGState(newState)
        })
    }, [dispatch])

    const hideRecordingSettings = useCallback(() => {
        closeModalMode()
        setTextSelection('')
        setMixpanelSource('')
        showRecSettingsDialog.setFalse()
    }, [closeModalMode, showRecSettingsDialog])

    const hideNoStepsMessageDialog = useCallback(() => {
        closeModalMode()
        showMessageDialog.setFalse()
    }, [closeModalMode, showMessageDialog])

    const hideQGStartPage = useCallback(() => {
        closeModalMode()
        showQGStartPage.setFalse()
    }, [closeModalMode, showQGStartPage])

    const openQGStartPage = useCallback(() => {
        showQGStartPage.setTrue()

        setTimeout(
            () => {
                hideQGStartPage()
            },
            isFirstQG ? 6000 : 3000
        )
    }, [hideQGStartPage, isFirstQG, showQGStartPage])

    // QuickGuidde start/restart capture
    const prevQGSettings = useRef<MagicCaptureType | undefined>(undefined)
    const startRecordingQG = useCallback(
        async (magicCapture?: MagicCaptureType) => {
            showRecSettingsDialog.setFalse()
            // QG start page should be shown before recording or on restart iframe dimensions are 0
            openQGStartPage()

            const dimensions = await getDimensions()

            prevQGSettings.current = magicCapture

            startQGRecording({
                dimensions,
                isCreatePlaybook: true,
                devicePixelRatio: window?.devicePixelRatio,
                ...(magicCapture ? { magicCapture } : {}),
            })
        },
        [openQGStartPage, showRecSettingsDialog]
    )

    const restartRecordingQG = useCallback(() => {
        showMessageDialog.setFalse()
        startRecordingQG(prevQGSettings?.current)
    }, [showMessageDialog, startRecordingQG])

    useEffect(() => {
        onRestartQG(() => {
            openModalMode()
            restartRecordingQG()
        })
    }, [openModalMode, restartRecordingQG])

    // Playbook start/restart capture
    const defaultFilters = useTagsParser()
    const location = useParentLocation()
    const startCapture = useCallback(() => {
        hideRecordingSettings()
        const params = {
            defaultFilters: {
                app: defaultFilters.app,
                appName: defaultFilters.appName,
                domain: defaultFilters.domain,
                tags: (defaultFilters.tags || []).concat(
                    textSelection ? [textSelection] : []
                ),
            },
            link: location?.href || '',
        }

        startScreenCapture(() => {
            logToAnalytics(mixpanelSource || 'start_recording', params)
            setTextSelection('')
            setMixpanelSource('')
        }, params)
    }, [
        hideRecordingSettings,
        defaultFilters.app,
        defaultFilters.appName,
        defaultFilters.domain,
        defaultFilters.tags,
        textSelection,
        location?.href,
        mixpanelSource,
    ])

    useEffect(() => {
        onRestartPB(() => {
            startCapture()
        })
    }, [startCapture])

    useParentListener(msgType => {
        if (msgType === RecordingIframeActionE.OPEN_SETTINGS) {
            // if the user has no organization id it is a new user (did not finish onboarding)
            // block the user from recording a QG
            if (!roles?.o || roles?.n) {
                almostThereDialog.setTrue()
                return
            }

            showRecSettingsDialog.setTrue()
        }

        if (msgType === RecordingIframeActionE.OPEN_NO_STEPS_MESSAGE) {
            openModalMode()
            showMessageDialog.setTrue()
        }

        if (msgType === RecordingIframeActionE.OPEN_PIN_EXT_CTA) {
            openModalMode()
            showPinExtDialog.setTrue()
        }
    })

    // Set quick guidde as default recording mode
    const {
        storageSetting: isScreenCamSet,
        changeSetting: setDefaultScreenCam,
    } = useStorageSetting(WEBCAM_SETTING, true)

    useEffect(() => {
        // If the setting is undefined, it is not loaded.
        // If it is false, it is loaded but not set, so we set it to default value.
        if (isScreenCamSet === false) {
            setDefaultScreenCam('stepByStep')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScreenCamSet]) // Only run on setting change

    return (
        <>
            <Dialog
                open={showRecSettingsDialog.isTrue}
                onClose={hideRecordingSettings}
                scroll="paper"
                classes={{
                    root: classes.main,
                    paper: clsx(
                        classes.modal,
                        showQGRecordOnly
                            ? classes.qgOnlyModal
                            : classes.regularModal
                    ),
                    container: classes.container,
                }}
                BackdropProps={{ style: { backdropFilter: 'blur(5px)' } }}
            >
                {isRecording && (
                    <RecPanel
                        mode={isQGRecording ? 'quickGuidde' : 'playbook'}
                        qgState={qgState}
                        closeModal={closeModalMode}
                    />
                )}
                {isProcessing && <ProgressState />}
                {!isRecording && !isProcessing && (
                    <VideoRecorder
                        startCapture={startCapture}
                        onClose={hideRecordingSettings}
                        onStartRecording={startRecordingQG}
                    />
                )}
            </Dialog>

            <NoStepsMessageDialog
                isModalOpen={showMessageDialog.isTrue}
                onClose={hideNoStepsMessageDialog}
                onRestart={restartRecordingQG}
            />

            {showPinExtDialog.isTrue && (
                <PinExtDialog
                    onClose={() => {
                        showPinExtDialog.setFalse()
                        closeModalMode()
                    }}
                />
            )}

            {almostThereDialog.isTrue && (
                <AlmostThereDialog
                    url={url}
                    onClose={() => {
                        almostThereDialog.setFalse()
                        closeModalMode()
                    }}
                />
            )}

            {showQGStartPage.isTrue && (
                <QuickGuiddeStart
                    isFirstQG={isFirstQG}
                    onClose={hideQGStartPage}
                />
            )}
        </>
    )
}
