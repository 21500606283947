import {
    Dialog,
    makeStyles,
    Theme,
    createStyles,
    Box,
    IconButton,
    Divider,
} from '@material-ui/core'

import pinExtGif from 'assets/images/pin-ext.gif'
import React from 'react'
import { SpacedGroup } from './SpacedGroup'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

type Props = {
    onClose: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 700,
            fontSize: '34px',
            lineHeight: '42px',
            textAlign: 'center',
        },
        body: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: 0.15,
            textAlign: 'center',
            color: 'rgba(9, 12, 16, 0.6)',
        },
        divider: {
            background: ' 1px solid rgba(9, 12, 16, 0.08)',
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
    })
)

export const PinExtDialog = ({ onClose }: Props) => {
    const classes = useStyles()

    return (
        <Dialog open={true} fullWidth maxWidth="sm" onClose={onClose}>
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
            <SpacedGroup flexDirection="column">
                <Box p={4}>
                    <Box className={classes.title} mt={2} mb={4.5}>
                        Pin guidde
                        <br />
                        to hide the panel
                    </Box>

                    <Box style={{ textAlign: 'center' }}>
                        <img
                            alt=""
                            title="Pin extension"
                            src={pinExtGif}
                            width="210px"
                        />
                    </Box>

                    <Box className={classes.body} mt={3} id="bodyText">
                        Keep your Guidde Extension visible at all times on your
                        browser
                    </Box>
                </Box>
                <Box py={1} width="100%">
                    <Divider variant="fullWidth" className={classes.divider} />
                </Box>
                <Box
                    my={2}
                    px={4}
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    boxSizing="border-box"
                >
                    <Button onClick={onClose}>
                        <Box
                            style={{
                                letterSpacing: 1.25,
                            }}
                        >
                            GOT IT
                        </Box>
                    </Button>
                </Box>
            </SpacedGroup>
        </Dialog>
    )
}
