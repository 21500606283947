import { getResourceURL } from '../services/extServices'

const EXT_ID = getResourceURL('')

// Main HTML Element IDs
export const LIB_IFRAME_ID = EXT_MODE ? 'GUIDDE-JS-EXT' : 'GUIDDE-JS-SDK'
export const REC_IFRAME_ID = 'GUIDDE-JS-EXT-REC'
export const REC_CONTROLS_ID = 'GUIDDE-JS-REC-CONTROLS'

// Minor HTML Element IDs
export const REC_PANEL_CONTAINER_ID = 'GUIDDE-JS-RecPanelContainer'
export const STOP_REC_ID = 'GUIDDE-JS-STOP-BTN'
export const COUNTDOWN_ID = 'GUIDDE-JS-TimeToRecEnd'
export const ADD_CHAPTER_ID = 'GUIDDE-JS-Add-Chapter'
export const DONE_REC_ID = 'GUIDDE-JS-Done'
export const CHAPTER_COUNT_ID = 'GUIDDE-JS-ChapterCount'
export const CHAPTER_COUNT_CIRCLE_ID = 'GUIDDE-JS-ChapterCountCircle'
export const CHANGE_REC_PANEL_MODE_ID = 'GUIDDE-JS-ChangeRecPanelMode'
export const FLIP_REC_PANEL_ID = 'GUIDDE-JS-FlipRecPanel'
export const RESTART_REC_ID = 'GUIDDE-JS-Restart'
export const DELETE_REC_ID = 'GUIDDE-JS-Delete'
export const PAUSE_QG_ID = 'GUIDDE-JS-Pause'
export const RESUME_QG_ID = 'GUIDDE-JS-Resume'

// Getters for main HTML elements
export const getLibIframe: () => HTMLIFrameElement | null = () =>
    document.getElementById(LIB_IFRAME_ID) as HTMLIFrameElement

export const getRecIframe: () => HTMLIFrameElement | null = () =>
    document.getElementById(REC_IFRAME_ID) as HTMLIFrameElement

export const getRecControls: () => HTMLElement | null = () =>
    document.getElementById(REC_CONTROLS_ID)

// Iframe sources and utils
const LIB_LOCAL_SRC = 'http://localhost:3101'
const REC_LOCAL_SRC = 'http://localhost:3102'
const LIB_BUILD_SRC = getResourceURL('cs/lib/index.html')
const REC_BUILD_SRC = getResourceURL('cs/rec/index.html')

export const EXT_LIB_IFRAME_SRC = Boolean(process.env.GUIDDE_LOCAL_SERVER)
    ? LIB_LOCAL_SRC
    : LIB_BUILD_SRC

export const EXT_REC_IFRAME_SRC = Boolean(process.env.GUIDDE_LOCAL_SERVER)
    ? REC_LOCAL_SRC
    : REC_BUILD_SRC

export const EXT_IFRAME_ALLOW = `camera ${EXT_ID};microphone ${EXT_ID};clipboard-read;clipboard-write`

const SDK_PREFIX = 'GUIDDE SDK: '
export const SDK_ERRORS = {
    WRONG_SNIPPET: SDK_PREFIX + 'Missing core variables.',
    CHUNK_LOADING:
        SDK_PREFIX +
        'Can not execute play(), the main chunk is not loaded yet.',
    INVALID_TENANT: SDK_PREFIX + 'Invalid tenant',
    EMPTY_PB_ID:
        SDK_PREFIX +
        'Could not execute play() - only non-empty strings are allowed as a playbookId parameter.',
    WRONG_USER_TYPE:
        SDK_PREFIX +
        'Could not process user information. Only objects are allowed, SDK is launched anonymously.',
    USER_TOO_LARGE:
        SDK_PREFIX +
        `GUIDDE SDK: User data exceeds allowed size of 512 characters. SDK is launched anonymously.`,
}
