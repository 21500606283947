import { getTenant, getToken } from '../firebase/auth'
import { isLocal } from '../services/environmentService'
import Config from '../config'
import { captureSentryError } from '../services/sentry'

export const getHost = () => {
    if (SDK_MODE) {
        return isLocal ? (window as any).localProxy : ''
    }

    if (EXT_MODE) {
        return `https://${Config.firebase.authDomain}`
    }
}

// For better error grouping in Sentry
export function ApiError(status: number, url: string, body: string) {
    // @ts-ignore
    this.name = `API Error ${status}: ${url}`
    // @ts-ignore
    this.message = body
}
ApiError.prototype = Error.prototype

export const request = (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body?: any
) =>
    getToken()
        .then(token => {
            if (!token) {
                return Promise.reject(
                    new ApiError(
                        401,
                        url,
                        'No token: ' + JSON.stringify(body, null, 2)
                    )
                )
            }

            return fetch(`${getHost()}${url}`, {
                method,
                headers: {
                    version: chrome?.runtime?.getManifest?.()?.version,
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`,
                    ...(SDK_MODE
                        ? {
                              'X-Guidde-Tenant': getTenant(),
                              'x-guidde-client': 'sdk',
                          }
                        : { 'x-guidde-client': 'ext' }),
                },
                ...(body && { body: JSON.stringify(body) }),
            })
        })
        .then((res: Response) => {
            if (res.ok) {
                return res.json()
            } else {
                return Promise.reject(
                    new ApiError(
                        res?.status,
                        res?.url,
                        JSON.stringify(body, null, 2)
                    )
                )
            }
        })
        .catch(e => {
            captureSentryError(e)
        })
