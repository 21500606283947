import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
import guiddeIcon from '../../assets/icons/guidde.svg'

const useStyles = makeStyles(({ spacing }) => ({
    progress: {
        width: '100px',
        margin: spacing(4, 'auto'),
    },
    spinner: {
        position: 'absolute',
        left: 'calc(50% - 50px)',
        top: spacing(4),
    },
    text: { textAlign: 'center', marginBottom: spacing(3) },
}))

export const ProgressState = () => {
    const classes = useStyles()

    return (
        <>
            <CircularProgress size={100} className={classes.spinner} />
            <img
                alt="Processing..."
                src={guiddeIcon}
                className={classes.progress}
            />
            <Typography className={classes.text}>
                Video is processing...
            </Typography>
        </>
    )
}
