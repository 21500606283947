import { useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'

import { RootState } from 'ducks/rootReducer'
import { option } from 'services/utils'

import { AddTag, Tag } from 'UI/Components'

type Props = {
    tags: Array<string>
    defaultTags: Array<string> | null
    onUpdateTags: (value: Array<string>) => void
    onUpdateDefTags: (value: Array<string>) => void
}

export const Tags = ({
    tags,
    defaultTags,
    onUpdateTags,
    onUpdateDefTags,
}: Props) => {
    const handleDeleteTag = index => {
        const newTags = [...tags]
        newTags.splice(index, 1)

        onUpdateTags(newTags)
    }

    const handleDeleteDefTag = index => {
        if (defaultTags) {
            const newDefTags = [...defaultTags]
            newDefTags.splice(index, 1)
            onUpdateDefTags(newDefTags)
        }
    }

    const handleAddTag = (tagValue: string) => {
        if (
            !!tagValue.replaceAll(' ', '') &&
            !tags.includes(tagValue) &&
            !defaultTags?.includes(tagValue)
        ) {
            onUpdateTags([...tags, tagValue])
        }
    }

    const availableTags = useSelector((state: RootState) => state.tags)

    const tagOptions: Array<{
        value: string
        label: string
    }> = availableTags
        ? Object.keys(availableTags).map((tag, idx) =>
              option(tag, `${tag} (${Object.values(availableTags)[idx]})`)
          )
        : []

    const tagsList = defaultTags
        ? tags.filter(tag => !defaultTags.includes(tag))
        : tags

    const defaultTagsLength = defaultTags?.length || 0
    const tagsListLength = tagsList.length
    const totalTagsLength = defaultTagsLength + tagsListLength

    const canShowTags = defaultTagsLength < 2

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            position="relative"
        >
            <Box display="flex" flexDirection="row" alignItems="center">
                {defaultTags?.slice(0, 2)?.map((defaultTag, idx) => (
                    <Tag
                        key={idx}
                        isHighlighted
                        tag={defaultTag}
                        onDelete={() => handleDeleteDefTag(idx)}
                    />
                ))}

                {canShowTags && (
                    <>
                        {tagsList.map((tag, idx) => (
                            <Tag
                                key={idx}
                                tag={tag}
                                onDelete={() =>
                                    handleDeleteTag(tags.indexOf(tag))
                                }
                            />
                        ))}
                    </>
                )}
            </Box>
            {/* show add tags component only if tags list is shorter than 2 items */}
            {totalTagsLength < 2 && (
                <AddTag
                    autocompleteOptions={tagOptions}
                    onApply={handleAddTag}
                />
            )}
        </Box>
    )
}
