import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    memo,
} from 'react'
import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import clsx from 'clsx'

import {
    Badge,
    Box,
    Button,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
} from '@material-ui/core'

import config from 'config'

import CloseIcon from '@material-ui/icons/Close'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import LaunchIcon from '@material-ui/icons/Launch'
import LinkIcon from '@material-ui/icons/Link'
import FastForwardIcon from '@material-ui/icons/FastForward'

import {
    logToAnalytics,
    openExternalLink,
    sendViewNotification,
} from 'background/services'

import { AppContext } from 'app/AppProvider'

import { VideoPlayer, ExtensionOnly } from 'UI/Components'

import { RootState } from 'ducks/rootReducer'
import { useBoolean, useRealtimeVideoTime, TypeUseBoolean } from 'hooks'

import { playbookToAnalyticsProps } from 'services/analytics'
import { generatePlayerId } from 'services/video'
import { sendMessageToParentScript } from 'services/parentService'
import { PlaybookType } from 'types'
import {
    getFromSessionStorage,
    setToSessionStorage,
} from '../../../services/utils'

const useStyles = makeStyles(() => ({
    videoWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingBottom: '330px',
        background: 'black',
    },
    controlBtn: {
        position: 'absolute',
        right: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(38 50 56 / 0.50);',
        minWidth: '35px !important',
        minHeight: '35px',
        borderRadius: '50%',
        padding: 0,
        opacity: 0,
        transform: 'translateX(30px)',
        transition: 'opacity 0.1s linear, transform 0.1s linear',
        '&:hover': {
            backgroundColor: 'rgb(38 50 56 / 80%)',
        },
        '& svg': {
            color: 'white',
        },
    },
    controlBtnVisible: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    closeBtn: {
        top: '8px',
    },
    transcriptBtn: {
        top: '50px',
        transitionDelay: '0.1s !important',
    },
    shareBtn: {
        top: '92px',
        transitionDelay: '0.2s !important',
    },
    // this will be used in the second phase with subtitleUrl
    // TODO: remember to change top positioning
    ccBtn: {
        top: '134px',
        transitionDelay: '0.2s !important',
    },
    speedBtn: {
        transitionDelay: SDK_MODE ? '0.1s !important' : '0.3s !important',
        top: SDK_MODE ? '86px' : '134px',
    },
    copyBtn: {
        top: '176px',
        transitionDelay: '0.4s !important',
    },
    editBtn: {
        transitionDelay: '0.5s !important',
        top: '218px',
    },
    copyList: {
        top: '200px',
        position: 'absolute',
        right: '8px',
        zIndex: 2,
    },
    badge: {
        left: '-4px',
        top: '-4px',
        fontWeight: 600,
        fontSize: '10px',
    },
}))

type Props = {
    hoverState: TypeUseBoolean
    showSidePanel: TypeUseBoolean
    showShareDialog: TypeUseBoolean
    playbook: PlaybookType
    startTime: number
}
export const VideoPanel = memo(
    ({
        hoverState,
        showSidePanel,
        showShareDialog,
        playbook,
        startTime,
    }: Props) => {
        const classes = useStyles()

        const { playbookMode } = useContext(AppContext)
        const [videoSpeed, changeSpeed] = useState(1)

        const copyDropdown = useBoolean()

        const realVideoTime = useRealtimeVideoTime()

        const isSDKEmbed = useSelector(
            (state: RootState) => state.sdkConfig.embed
        )

        const { isPublic } = playbook

        const increaseVideoSpeed = useCallback(() => {
            changeSpeed(prevSpeed => {
                const newSpeed = prevSpeed === 2 ? 1 : prevSpeed + 0.25
                const playerId = generatePlayerId()
                const player = document.getElementById(
                    playerId
                ) as HTMLVideoElement
                player.playbackRate = newSpeed

                return newSpeed
            })
        }, [])

        const mixpanelPayload = playbookToAnalyticsProps(playbook)

        const toggleShareDialog = () => showShareDialog.toggle()

        const { user, roles } = useSelector((state: RootState) => state.user)
        const { uid } = user

        const isOwner = playbook?.creator_uid === user.uid

        // Send email notification to the video owner every time when someone watches playbook
        useEffect(() => {
            if (
                playbook &&
                Object.values(playbook).length > 0 &&
                !isOwner &&
                !roles?.s
            ) {
                const viewKey = `playbookViews/${user.uid}/${playbook.id}`
                const isViewed = !!getFromSessionStorage(viewKey)
                if (isViewed) return

                sendViewNotification(() => {}, {
                    orgId: roles?.o,
                    uid: user.uid,
                    playbookId: playbook.id,
                })
                logToAnalytics('videoPlay', playbookToAnalyticsProps(playbook))

                setToSessionStorage(viewKey, Date.now().toString())
            }
        }, [playbook, roles?.o, roles?.s, user?.uid, isOwner])

        const canEditPlaybook =
            isOwner ||
            roles?.s ||
            (roles?.a === 100 && roles?.o === playbook.organizationId)

        const videoCopyLink = useMemo(() => {
            const path =
                (isPublic ? '/share/playbooks/' : '/playbooks/') +
                playbook.id +
                '?origin=' +
                uid

            return config.firebase.authDomain + path
        }, [playbook, uid])

        const onCopyLink = useCallback(() => {
            logToAnalytics('copyLink', {
                context: 'video-player',
                ...playbookToAnalyticsProps(playbook),
            })
        }, [])

        return (
            <>
                <Box className={classes.videoWrapper}>
                    <VideoPlayer
                        playbookURL={playbook.url}
                        startTime={startTime}
                    />
                    {/*<VideoReactionsAnswers*/}
                    {/*    playbookId={playbook.id}*/}
                    {/*    showReactions={showReactions}*/}
                    {/*    showFinishStep={showFinishStep}*/}
                    {/*/>*/}
                </Box>

                <Button
                    title="Close video"
                    color="default"
                    onClick={() => {
                        logToAnalytics('videoClose', mixpanelPayload)
                        playbookMode.setFalse()

                        sendMessageToParentScript({
                            action: 'RESIZE_TO_NORMAL',
                            params: { close: true, isSDKEmbed },
                        })
                    }}
                    size="small"
                    className={clsx(
                        classes.controlBtn,
                        classes.closeBtn,
                        hoverState.isTrue && classes.controlBtnVisible
                    )}
                >
                    <CloseIcon />
                </Button>

                <Button
                    title="Toggle side panel"
                    color="default"
                    onClick={() => {
                        logToAnalytics(
                            showSidePanel.isTrue
                                ? 'hide_transcript'
                                : 'show_transcript',
                            mixpanelPayload
                        )
                        showSidePanel.toggle()
                    }}
                    size="small"
                    className={clsx(
                        classes.controlBtn,
                        classes.transcriptBtn,
                        hoverState.isTrue && classes.controlBtnVisible
                    )}
                >
                    <LaunchIcon />
                </Button>

                <ExtensionOnly>
                    <Button
                        title="Share playbook"
                        color="default"
                        onClick={() => {
                            logToAnalytics(
                                'share_pb_btn_clicked',
                                mixpanelPayload
                            )
                            toggleShareDialog()
                        }}
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.shareBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                        style={{ pointerEvents: 'auto' }}
                    >
                        <ShareIcon />
                    </Button>

                    {/*Show/Hide subtitles button*/}
                    {/*this will be implemented on the second phase with subtitles*/}
                    {/*<Button*/}
                    {/*    title={`${*/}
                    {/*        isShowSubtitlesButton.isTrue ? 'Hide' : 'Show'*/}
                    {/*    } subtitles`}*/}
                    {/*    color="default"*/}
                    {/*    size="small"*/}
                    {/*    className={clsx(*/}
                    {/*        classes.controlBtn,*/}
                    {/*        classes.ccBtn,*/}
                    {/*        hoverState.isTrue && classes.controlBtnVisible*/}
                    {/*    )}*/}
                    {/*    onClickCapture={() => {*/}
                    {/*        isShowSubtitlesButton.toggle()*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <ClosedCaptionIcon />*/}
                    {/*</Button>*/}

                    <Button
                        title="Change video speed"
                        color="default"
                        onClick={increaseVideoSpeed}
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.speedBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                    >
                        <Badge
                            overlap="rectangular"
                            badgeContent={`${(
                                Math.round(videoSpeed * 100) / 100
                            ).toFixed(2)}x`}
                            color="primary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            classes={{
                                badge: classes.badge,
                            }}
                        >
                            <FastForwardIcon />
                        </Badge>
                    </Button>

                    <Button
                        title="Copy video link"
                        color="default"
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.copyBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                        onClick={copyDropdown.toggle}
                    >
                        <LinkIcon />
                    </Button>

                    {copyDropdown.isTrue && hoverState.isTrue && (
                        <Paper className={classes.copyList}>
                            <MenuList
                                id="split-button-menu"
                                onClick={copyDropdown.setFalse}
                                onMouseLeave={copyDropdown.setFalse}
                            >
                                <CopyToClipboard
                                    text={videoCopyLink}
                                    onCopy={onCopyLink}
                                >
                                    <MenuItem>Copy Link</MenuItem>
                                </CopyToClipboard>
                                <CopyToClipboard
                                    text={`${videoCopyLink}&t=${realVideoTime}`}
                                    onCopy={onCopyLink}
                                >
                                    <MenuItem>
                                        Copy Link at current time
                                    </MenuItem>
                                </CopyToClipboard>
                            </MenuList>
                        </Paper>
                    )}

                    {canEditPlaybook && (
                        <Button
                            title="Edit video"
                            color="default"
                            size="small"
                            className={clsx(
                                classes.controlBtn,
                                classes.editBtn,
                                hoverState.isTrue && classes.controlBtnVisible
                            )}
                            onClick={() => {
                                openExternalLink(null, {
                                    url: `playbooks/${playbook.id}/edit`,
                                })
                            }}
                        >
                            <EditIcon />
                        </Button>
                    )}
                </ExtensionOnly>
            </>
        )
    }
)
