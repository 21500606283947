import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { Avatar, Box, makeStyles, Typography } from '@material-ui/core'
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@material-ui/lab'
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked'

import { ExtensionOnly, PlaybookVisibility } from 'UI/Components'

import { RootState } from 'ducks/rootReducer'
import { setActivePlaybook } from 'ducks/actions'

function pad(num) {
    return ('0' + num).slice(-2)
}

const formatDuration = duration => {
    const minutes = Math.floor(duration / 60) % 60
    const secs = Math.floor(duration % 60)

    return `${pad(minutes)}:${pad(secs)}`
}

const useStyles = makeStyles({
    timeLine: {
        padding: 0,
    },
    timeLineItem: {
        minHeight: '90px',
        '&:before': {
            display: 'none',
        },
    },
    enabledTimeLineItem: {
        cursor: 'pointer',
    },
    playlistInfo: {
        marginTop: '-16px',
    },
    timeLineContent: {
        padding: '6px 16px',
        width: '100%',
        position: 'absolute',
        top: '-25%',
        left: '8px',
    },
    removePartButton: {
        position: 'absolute',
        padding: '4px',
        top: '4px',
        right: '8px',
    },
    title: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    timeLineSeparator: {
        marginLeft: '-6px',
    },
    notActivePart: {
        opacity: 0.5,
    },
    index: {
        position: 'absolute',
        lineHeight: 1,
    },
    thumbnail: {
        width: '120px',
        minWidth: '120px',
        height: '70px',
        backgroundColor: 'black',
        border: '1px solid gainsboro',
    },
    avatar: {
        width: '20px',
        height: '20px',
    },
})

export const Playlist = () => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const playlist = useSelector((state: RootState) => state.playbook)

    const { playbookList, active } = useSelector(
        (state: RootState) => state.playlist
    )

    return (
        <Box display="flex" m={-2}>
            <Timeline classes={{ root: classes.timeLine }}>
                <TimelineItem classes={{ root: classes.timeLineItem }}>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={classes.playlistInfo}>
                        <Typography>
                            <b>{playlist.title}</b>
                        </Typography>

                        <Box display="flex" alignItems="center">
                            <Avatar
                                className={classes.avatar}
                                src={playlist?.userImage}
                            />

                            <Box ml={1} mr={0.5}>
                                <Typography variant="body2">
                                    {playlist?.username}
                                </Typography>
                            </Box>

                            <Box display="flex">
                                <ExtensionOnly>
                                    <PlaybookVisibility
                                        visibility={playlist.visibility}
                                    />
                                </ExtensionOnly>

                                {playbookList?.length && (
                                    <Box ml={1}>
                                        <Typography variant="body2">
                                            {active + 1}/{playbookList?.length}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </TimelineContent>
                </TimelineItem>
                {playbookList?.map((playbook, idx) => (
                    <TimelineItem
                        key={idx}
                        classes={{
                            root: clsx(
                                classes.timeLineItem,
                                classes.enabledTimeLineItem
                            ),
                        }}
                        // @ts-ignore
                        onClick={() => dispatch(setActivePlaybook(idx))}
                    >
                        <TimelineSeparator
                            className={classes.timeLineSeparator}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                className={
                                    idx !== active
                                        ? classes.notActivePart
                                        : undefined
                                }
                            >
                                <CircleIcon />
                                <Typography
                                    className={classes.index}
                                    variant="body2"
                                >
                                    {idx + 1}
                                </Typography>
                            </Box>
                            {idx !== playbookList?.length - 1 && (
                                <TimelineConnector />
                            )}
                        </TimelineSeparator>

                        <TimelineContent className={classes.timeLineContent}>
                            <Box display="flex">
                                <img
                                    className={classes.thumbnail}
                                    src={playbook.screenshotUrl}
                                    alt="thumbnail"
                                />

                                <Box ml={1} pr={2}>
                                    <Typography
                                        className={classes.title}
                                        title={playbook.title}
                                    >
                                        {playbook.title}
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatDuration(playbook.duration)}
                                    </Typography>
                                </Box>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    )
}
