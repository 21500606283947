import React, { useMemo } from 'react'

import {
    Box,
    Checkbox,
    ClickAwayListener,
    makeStyles,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'

import { option } from 'services/utils'
import { useBoolean } from 'hooks'

const useStyles = makeStyles(theme => ({
    closedContainer: {
        cursor: 'pointer',
    },
    paper: {
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.23)',
    },
    listbox: {
        padding: 0,
    },
    option: {
        padding: theme.spacing(0.5, 1),
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
    },
    checkbox: {
        padding: 4,
    },
}))

export const MultipleSelect = ({
    value,
    onChange,
    options,
    defaultLabel,
    labelIcon,
    defaultOption,
    multipleItemsLabel,
    ...rest
}) => {
    const theme = useTheme()
    const classes = useStyles()

    const allOptions = defaultOption ? [defaultOption, ...options] : options

    const selectedOptions = allOptions.filter(o => value.includes(o.value))

    const open = useBoolean()

    if (open.isFalse) {
        return (
            <Box
                {...rest}
                display="flex"
                flexDirection="row"
                alignItems="center"
                className={classes.closedContainer}
                tabIndex={0}
                onFocus={open.setTrue}
            >
                <Typography
                    style={{
                        display: 'flex',
                        fontSize: '14px',
                        whiteSpace: 'nowrap',
                        color:
                            selectedOptions.length === 0 ||
                            (defaultOption &&
                                selectedOptions
                                    .map(o => o.value)
                                    .includes(defaultOption.value))
                                ? theme.palette.text.primary
                                : theme.palette.primary.main,
                    }}
                >
                    {labelIcon}
                    {selectedOptions.length === 0 && defaultLabel}
                    {selectedOptions.length === 1 && selectedOptions[0].label}
                    {selectedOptions.length > 1 &&
                        `${multipleItemsLabel}:${selectedOptions.length}`}
                </Typography>
                <ExpandMoreIcon />
            </Box>
        )
    }

    return (
        <ClickAwayListener onClickAway={open.setFalse}>
            <Box>
                <Autocomplete
                    open
                    {...rest}
                    innerRef={node => {
                        if (node) {
                            const input = node.getElementsByTagName('input')[0]
                            input?.select()
                        }
                    }}
                    autoHighlight
                    multiple
                    disableCloseOnSelect
                    disableListWrap
                    disablePortal
                    style={{ width: 200, zIndex: 999 }}
                    popupIcon={null}
                    value={selectedOptions}
                    onChange={(_event, newValue) => {
                        onChange(
                            newValue.map(o => o.value),
                            selectedOptions.map(o => o.value)
                        )
                    }}
                    onBlur={open.setFalse}
                    options={allOptions}
                    renderInput={({ InputProps, ...params }) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...InputProps,
                                disableUnderline: true,
                                startAdornment: <SearchIcon />,
                            }}
                            onKeyDown={event => {
                                // Disable selected items deletion by pressing backspace
                                if (event.keyCode === 8) {
                                    event.stopPropagation()
                                }
                            }}
                        />
                    )}
                    renderOption={(o, { selected }) => (
                        <>
                            <Checkbox
                                checked={selected}
                                className={classes.checkbox}
                                color="primary"
                            />
                            {o.label}
                        </>
                    )}
                    getOptionLabel={o => o.label || ''} // Temporary hack
                    classes={{
                        paper: classes.paper,
                        listbox: classes.listbox,
                        option: classes.option,
                    }}
                />
            </Box>
        </ClickAwayListener>
    )
}

export const allIfEmpty = values => (values.length === 0 ? ['ALL'] : values)

export const handleChange = allValues => (values, prevValues) => {
    if (values.length === 0 && prevValues[0] === 'ALL') {
        return allValues
    }

    if (values.length === 0 || values[values.length - 1] === 'ALL') {
        return []
    }

    return values.filter(value => value !== 'ALL')
}

export const FilterSelectMultiple = props => {
    const getNewValue = useMemo(
        () => handleChange(props.options.map(option => option.value)),
        [props.options]
    )

    return (
        <MultipleSelect
            value={allIfEmpty(props.value)}
            onChange={(values, prevValues) => {
                props.onChange(getNewValue(values, prevValues))
            }}
            style={{ fontSize: '14px' }}
            multipleItemsLabel={props.multipleItemsLabel}
            defaultLabel={`All ${props.multipleItemsLabel}`}
            labelIcon={props.labelIcon}
            options={props.options}
            defaultOption={option('ALL', `All ${props.multipleItemsLabel}`)}
        />
    )
}
