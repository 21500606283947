import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    makeStyles,
    Box,
    LinearProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ConnectedInput } from 'UI/Components'
import { useSelector } from 'react-redux'
import { RootState } from 'ducks/rootReducer'
import {
    getOrganizationData,
    getOrgUsers,
    sendInvite,
} from 'background/services'
import { useNotification } from 'hooks/use-notification'

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '1.5rem',
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold as number,
    },
    fieldTitle: {
        fontSize: '14px',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
    },
    actions: {
        padding: theme.spacing(3),
        justifyContent: 'center',
    },
    emailDescription: {
        color: '#78909c',
        fontSize: '14px',
    },
    description: {
        fontSize: '14px',
    },
    content: {
        height: '37vh',
    },
    counter: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        color: '#78909c',
    },
    textField: {
        padding: '18.5px 14px 32px 14px',
    },
}))

export const InviteDialog = ({ isOpen, onClose }) => {
    const classes = useStyles()

    const getMessageFields = (orgName: string) => ({
        initialValue: `Hey,\nOur team at ${orgName} is using Guidde to magically create video documentation using AI. You should try it out for yourself here 👇`,
        maxLength: 1500,
    })

    const [org, setOrg] = useState<{
        name?: string
        domains?: Array<string>
    }>({ name: '', domains: [] })

    const [orgUsers, setOrgUsers] = useState<Array<{ email: string }>>([])

    const orgId = useSelector((state: RootState) => state.user.roles?.o)

    const { showSuccessNotification } = useNotification()

    const allowedDomains = org.domains?.join('|')

    const existingUsers = orgUsers?.map(user => user.email).join('|')

    const domainValidation = new RegExp(
        `^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(${allowedDomains})$`
    )

    const userExistValidation = new RegExp(`^(?!(${existingUsers}))`)

    const emailPlaceholder =
        org?.domains && `name@${org?.domains[0]}, name@${org?.domains[0]}`

    useEffect(() => {
        getOrganizationData(setOrg)
        getOrgUsers(setOrgUsers)
    }, [])

    if (!org.name) return <LinearProgress />

    const messageFields = getMessageFields(org.name)

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            PaperProps={{
                style: {
                    overflowY: 'hidden',
                },
            }}
        >
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>

            <Formik
                onSubmit={({ emails, message }) => {
                    sendInvite(
                        () => {
                            showSuccessNotification(
                                'Invite was sent successfully'
                            )
                            onClose()
                        },
                        {
                            emails,
                            message,
                            orgId,
                        }
                    )
                }}
                initialValues={{
                    emails: null,
                    message: messageFields.initialValue,
                }}
                validationSchema={yup.object().shape({
                    emails: yup
                        .array()
                        .min(1, 'Field is required')
                        .nullable()
                        .required('Field is required')
                        .transform(function (value, originalValue) {
                            if (this.isType(value) && value !== null) {
                                return value
                            }
                            return originalValue
                                ? originalValue.split(/[,]+/)
                                : []
                        })
                        .of(
                            yup
                                .string()
                                .trim()
                                .nullable()
                                .required('Email after comma is required')
                                .email(({ value }) =>
                                    /\s/.test(value)
                                        ? 'Whitespace is not allowed'
                                        : `${value} is not a valid email `
                                )
                                .matches(
                                    domainValidation,
                                    `Allowed domain${
                                        (org?.domains?.length || 0) > 1
                                            ? 's'
                                            : ''
                                    }: ${org?.domains}`
                                )
                                .matches(
                                    userExistValidation,
                                    'This user is already a member of organization'
                                )
                        ),
                    message: yup.string().trim().nullable(),
                })}
            >
                {({ dirty, isValid, values: { message }, isSubmitting }) => (
                    <Form>
                        <DialogTitle>
                            <Typography className={classes.title}>
                                Invite new teammates
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers className={classes.content}>
                            <Box mt={1}>
                                <Typography className={classes.description}>
                                    Your teammates will receive an email with an
                                    invitation to join {org?.name}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography className={classes.fieldTitle}>
                                    <b>Email address(s)</b>
                                </Typography>
                                <Box mt={1}>
                                    <ConnectedInput
                                        name="emails"
                                        placeholder={emailPlaceholder}
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Typography
                                        className={classes.emailDescription}
                                    >
                                        To invite multiple teammates, separate
                                        email addresses with commas.
                                    </Typography>
                                </Box>
                            </Box>

                            <Box mt={2}>
                                <Typography className={classes.fieldTitle}>
                                    <b>Message</b>
                                </Typography>
                                <Box mt={1} position="relative">
                                    <ConnectedInput
                                        name="message"
                                        placeholder="Enter the message"
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            maxLength: messageFields.maxLength,
                                            style: {
                                                marginBottom: '16px',
                                            },
                                        }}
                                    />
                                    <Typography className={classes.counter}>
                                        {message.length}/
                                        {messageFields.maxLength}
                                    </Typography>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <Button
                                size="medium"
                                color="default"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="medium"
                                type="submit"
                                disabled={(dirty && !isValid) || isSubmitting}
                            >
                                Send Invitation
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
